/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'Editor/_wickbrand.scss';

.menu-bar {
  height: 100%;
  width: 100%;
  background-color: $editor-primary;
  overflow: visible;
  border: $editor-outline-padding solid $editor-primary-outline;
  display: flex;
  box-sizing: border-box;
  align-items: center;
}

.menu-bar-project-name {
  transition: .4s;
  font-size: 18px;
  font-family: 'Nunito Sans';
  color: $editor-menubar-project-text;
  cursor: pointer;
  margin: 0 auto;
}

.menu-bar-info-container {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.menu-bar-project-name-mobile {
  transition: .4s;
  font-size: 18px;
  font-family: 'Nunito Sans';
  color: $editor-menubar-project-text;
  text-align: center;
  margin: 0 auto;
}

// .menu-bar-project-name:hover {
//   color: $editor-menubar-option-text;
// }

.menu-bar-actions-container {
  display: flex;
  align-items: center;
  font-size: 16px; //14px;
  font-family: 'Nunito Sans';
  float: right;
  height: 100%;
}

.project-settings-preview {
  height: 70%;
  padding-left: 8px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
