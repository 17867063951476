/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'Editor/_wickbrand.scss';

$info-left-margin: 12px; 

.export-modal-body {
  width: 450px;
  min-width: 240px;  
  padding: 20px; 
  transition: height .5s, width .5s;
  transition-timing-function: ease-in-out;
}

.export-modal-body.mobile {
  width: 90%;
  max-width: 400px;
}


#export-modal-title {
  font-size: 18px;
  color: $editor-modal-text-gray;
  font-weight: bold; 
  text-align: left;
  width: 100%; 
}

#export-modal-interior-content {
  display: flex;
  flex-direction: column;
  width: 100%; 
  height: 100%;
  justify-content: center;
  align-items: center;
}

#export-modal-footer {
  width: 100%; 
  height: 28px;
  margin-top: 10px;
  display: flex;
  flex-direction: row; 
  align-items: center;
}

#export-modal-footer > div:last-child {
  margin-left: $info-left-margin; 
}

#export-modal-name-input {
  margin-top: 10px;
  width: 100%; 
}

.export-info-container {
  display: flex;
  flex-direction: row; 
  width: 100%; 
}
.export-info-container.mobile {
  flex-direction: column;
}

.export-info-container:not(.mobile) > div:last-child {
  margin-left: $info-left-margin;
}

.export-info-item {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.export-info-item.mobile {
  width: 100%;
  margin-bottom: 5px;
}

.wide-export-info-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 0 !important;
}

.export-modal-button-container {
  width: 100%; 
  height: 28px;
  margin-top: 10px;
}

.export-object-info {
  height: 120px; 
}

.export-object-info-off {
  background-color: #222222; 
}

#export-sub-tab-list {
  height: 40px; 
  min-height: 40px; 
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: left;
  overflow: auto; 
}

.export-modal-advanced-options {
  display: flex;
  flex-direction: column;
}

.export-modal-advanced-option-title {
  color: white;
  font-size: 18px;
  margin-bottom: 0;
}

.export-modal-advanced-option-label {
  display: inline-flex;
  color: white;
  font-size: 18px;
}

.export-modal-advanced-checkbox-container {
  margin-top: 8px;
  display: flex;
  margin-left: auto;
}

.advanced-resolution-table td {
  border-left: 0px;
  padding-left: 4px;
  color: white; 
}

#advanced-resolution-dropdown {
  width: 100px;
  margin-right: 8px;
}

.export-modal-resolution-inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.export-modal-resolution-label {
  text-align: left;
  color: $editor-modal-text-gray; 
  margin-bottom: 2px;
}

.export-modal-resolution-input-container {
  text-align: left;
  height: 25px;
  width: 50%;
}

.export-modal-resolution-dropdown-container {
  vertical-align: middle;
  width: 50%;
  margin-top: auto;
}

.export-modal-resolution-inputs-container {
  margin-left: 8px; 
  display: flex;
  width: 50%;
  text-align: center;
}

.export-modal-x-symbol {
  padding-top: 150%;
  padding-left: 5px; 
  padding-right: 5px;
  color: $editor-modal-text-gray;
}