/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'Editor/_wickbrand.scss';

$numeric-input-background: $editor-text-input-background;

.wick-input-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.wick-input {
  padding: 2px;
  padding-left: 4px;
  width: 100%;
  height: 100%;
  border-radius: $interface-input-roundness;
  border: none;
  background-color: $editor-text-input-background;
  color: white;
}

.wick-input.invalid {
   border-left: $editor-input-border-width solid $wick-red !important;
}

.wick-input-updating {
  border: $editor-input-border-width solid $wick-yellow !important;
}

.wick-input.read-only {
    background-color: gray;
}

.wick-slider {
  width: 100%;
  height: 100%;
  border-radius: $interface-input-roundness;
  margin-top: auto;
}

.wick-checkbox {
  min-width: 20px;
  min-height: 20px;
}

.wick-checkbox:hover {
  cursor: pointer;
}

.wick-checkbox-label {
  margin: 0; 
  margin-right: 4px;
  color: white;
}

.wick-checkbox-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}

.wick-button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: $interface-roundness-primary;
  cursor: pointer;

  padding: 2px;
}

.wick-slider {
  width: 100%;
  display: flex;
  align-items: center;
}


// SELECTOR

.wick-select {
  width: 100%;
  height: 100%;
}

.wick-select-container {
  font-size: 15px;
  height: 26px;
}

.wick-select-inner {
  font-size: 20px;
}

.wick-select-control{
  padding: 0 !important; 
  padding-left: 2px !important; 
  display: flex; 
  align-items: center;
  min-height: 26px;
}

.wick-select-menu {
  font-size: 10px;
  background-color: #EAEAEA; 
  color: #1D1921;
}

.Dropdown-option {
  padding: 2px !important; 
  white-space: nowrap; 
  background-color: #EAEAEA;  
  color: #1D1921;
}

.Dropdown-option.is-selected {
  background-color: #29F1A3!important;
  color: #1D1921 !important; 
}

.Dropdown-placeholder {
  white-space: nowrap;
  max-width: 110px; 
}

.wick-tooltip {
  max-width: 220px !important; 
  opacity: 1 !important; 
  background-color: #265A87 !important;
  font-size: 14px !important;
}

.wick-input-select {
  width: 100%;
  color: "black";
  font-size: "16px";
  background-color: "white";
  display: "flex";
  height: 100% !important;
  border-radius: 4px !important;
}

.wick-input-select__control {
  width: 100%;
  height: 100%;
  min-height: auto !important;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
}

.wick-input-select__menu {
  margin-top: 0px !important;
}

.wick-input-select__value-container {
  padding: 0px;
}

.wick-input-select__dropdown-indicator {
  padding: 0px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}