/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'Editor/_wickbrand.scss';

$margin: 3%;

.builtin-library {
  width: 100%;
  height: 100%;
  padding: 20px;
}

.builtin-library-modal-title {
  height: 30px;
  font-size: 18px;
  color: $editor-modal-text-gray;
  font-weight: bold;
  text-align: left;
  width: 100%;
}

.builtin-library-asset-grid {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  margin-top: 5px;
}

.builtin-library-asset {
  width: 30%;
  height: 170px;
  margin-right: $margin;
  margin-bottom: 10px; 
}

.builtin-library-asset-name {
  color: white;
  width: 100%;
  height: 30px;
  overflow: hidden;
}

.builtin-library-asset-icon-container {
  width: 100%;
  height: 100px;
  background-color: $editor-modal-dark;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.builtin-library-asset-icon {
  display: block;
  width: auto;
  height: 100%;
}

.add-as-asset-button {
  width: 100%;
  height: 28px;
  margin: $margin;
  padding-right: calc(#{$margin} * 2);
}

.preview-sound-button {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  height: 50px;
  margin-bottom: 12px;
}
.preview-sound-button-text {
  position: relative;
  top: 25%;
}

.audio-preview {
  width: 100%;
  height: 50px;
}