/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

:export {
  editorCanvasBorder: #6A6A6A; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

:export {
  editorCanvasBorder: #6A6A6A; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.tool-box {
  width: 100%;
  height: 100%;
  background-color: #303030;
  padding-left: 2px;
  padding-right: 4px;
  border: 4px solid #191919;
  border-top: none;
  border-right: none;
  display: flex;
  overflow-x: hidden; }

.tool-box-large {
  flex-direction: row;
  align-items: center;
  overflow: hidden; }

.tool-box.tool-box-medium {
  flex-direction: column;
  overflow: hidden;
  height: 80px; }

.medium-toolbox-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 3px solid #191919;
  width: 100%;
  height: 50%; }

.medium-toolbox-row:last-child {
  border-bottom: none; }

.tool-box-container {
  height: 100%;
  width: 100%;
  overflow: hidden; }

.tool-collection-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%; }

.toolbox-item {
  width: 30px;
  max-width: 30px;
  height: 30px;
  margin-left: 3px;
  margin-right: 3px; }

.toolbox-item.mobile {
  margin-right: 0; }

.tool-selector-item {
  margin-top: 3px;
  margin-bottom: 3px; }

.tool-selector-item:last-child {
  margin-bottom: 0; }

.toolbox-actions-center {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center; }

.color-container {
  cursor: pointer;
  width: calc(30px * .85);
  height: calc(30px * .85);
  float: left;
  min-width: calc(30px * .85);
  align-items: center;
  display: flex;
  overflow: hidden; }

#tool-box-stroke-color-button {
  border-radius: 20px; }

#tool-box-fill-color-button {
  border-radius: 20px; }

#stroke-color-picker-container {
  box-sizing: border-box; }

.toolbox-actions-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: right; }

.toolbox-actions-right {
  display: flex;
  justify-content: center;
  align-items: center; }

.toolbox-actions-right-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
  height: 100%; }

.bump-up-no-dropdown {
  margin-bottom: 2.5px; }

#settings-panel-container {
  display: flex;
  justify-content: center;
  background-color: transparent;
  align-items: center; }

.settings-input-container {
  display: flex;
  justify-content: center;
  align-items: center; }

.brush-modes-widget {
  height: 35px;
  display: flex;
  align-items: center;
  margin: 1px; }
