/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

 @import 'Editor/_wickbrand.scss';

 $settings-modal-height: 375px;
 $settings-title-height: 32px;
 $modal-margin: 15px;
 $settings-body-height: $settings-modal-height - $settings-title-height - (2 * $modal-margin);

.settings-modal-title {
    height: $settings-title-height;
    min-height: $settings-title-height; 
    font-size: 18px;
    color: $editor-modal-text-gray;
    font-weight: bold; 
    text-align: left;
    width: 100%;
}

.settings-modal-body {
    width: 100%;
    height: $settings-body-height;
}

.settings-modal-container {
    width: 500px;
    height: $settings-modal-height;
}
.settings-modal-container.mobile {
    width: 80%;
    max-width: 400px;
    height: 500px;
}