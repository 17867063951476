/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

:export {
  editorCanvasBorder: #6A6A6A; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

:export {
  editorCanvasBorder: #6A6A6A; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.outliner {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.popout-outliner {
  border-right: 0; }

.outliner-body {
  color: white;
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.outliner-body:hover {
  overflow-y: auto; }

.outliner-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: flex;
  flex-direction: column; }

.outliner-script-icon {
  vertical-align: top;
  margin-top: calc(4px/2);
  height: 16px; }

.outliner-sound-icon {
  vertical-align: top;
  margin-top: 0;
  height: 20px;
  margin-right: 4px; }

.outliner-object {
  align-content: flex-start;
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  background-color: #3B3B3B;
  border-top: 2px solid #303030;
  border-bottom: 2px solid #303030; }

.outliner-object:hover {
  background-color: #4A4A4A;
  cursor: pointer; }

.outliner-object-selector {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 100%;
  border-style: none;
  background-color: transparent; }

.object-selected {
  border: 2px solid #1EE29A; }

.object-dragging {
  opacity: 0.5; }

.outliner-object-container {
  position: relative;
  width: 100%;
  height: 100%; }

.highlighted {
  background-color: #4A4A4A; }

.outliner-name {
  position: relative;
  z-index: -1;
  color: white;
  font-size: 16px;
  margin-left: calc(4px * 2); }

.row-icon {
  position: relative;
  z-index: -1;
  width: 14px;
  margin-left: 4px;
  margin-bottom: 3px; }

@keyframes expand {
  0% {
    transform: scaleY(0); }
  100% {
    transform: scaleY(1); } }

.indentation {
  animation-timing-function: ease-in;
  padding-left: 25px;
  transform-origin: top;
  animation-name: expand;
  animation-duration: 75ms;
  animation-fill-mode: forwards; }

.outliner-buttons-container {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 0;
  float: right;
  margin-top: calc(4px / 2); }

.hover-top {
  border-top: calc(2px * 2) #00ADEF solid; }

.hover-middle {
  background-color: #00ADEF; }

.hover-bottom {
  border-bottom: calc(2px * 2) #00ADEF solid; }

.widget {
  vertical-align: top;
  max-width: 20px;
  max-height: 20px !important;
  width: 20px;
  height: 20px !important;
  border: none;
  background: none;
  padding: 0;
  margin-right: 4px;
  transition: background-color 150ms ease-in-out;
  border-radius: 3px; }

.widget-off {
  max-height: 20px;
  vertical-align: top;
  opacity: 0.25; }

.widget-on {
  max-height: 20px;
  vertical-align: top;
  opacity: 1; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(90deg); } }

.outliner-dropdown-icon {
  position: relative;
  z-index: 0;
  margin-left: 4px;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  transition: background-color 150ms ease-in-out; }

.outliner-dropdown-icon:hover {
  background-color: rgba(255, 255, 255, 0.2); }

.expanded {
  animation-name: rotate;
  animation-duration: 75ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards; }

.empty {
  padding: calc(15px * .3);
  z-index: -1; }

.empty:hover {
  background: none; }
