/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

 @import 'Editor/_wickbrand.scss';
 
$header-height: 30px;

.tabbed-interface {
    width: 100%;
    height: 100%;
}

.tabbed-interface-body {
    width: 100%;
    height: calc(100% - #{$header-height});
}

.tabbed-interface-main-tab-container {
    height: $header-height; 
    min-height: $header-height; 
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: left;
    overflow: auto; 
}

.tabbed-interface-main-tab {
    background: none;
    border: none;
    margin-left: 15px; 
    background-color: $editor-modal-gray;
    border: none;
    border-bottom: 2px solid #484747; //$editor-popout-secondary; 
    color: #CFCFCF; //$editor-popout-tertiary-text; 
    font-size: 16px;
    cursor: pointer;
}

.tabbed-interface-main-tab:first-child {
    margin-left: 0px; 
}

.tabbed-interface-main-tab.selected {
    transition: .4s;
    border-bottom: 2px solid $wick-green; 
    color: white; 
}
