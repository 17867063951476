/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'Editor/_wickbrand.scss';

#modal-close-icon-container {
  background-color: $editor-modal-gray;
  border: none;
  position: absolute;
  top: 15px; 
  right: 15px; 
  width: 20px;
  height: 20px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

#modal-close-icon-container:hover {
  cursor: pointer; 
}

#modal-icon-container {
  width: 60px;
  height: 60px;
}

#modal-modal-icon {
  width: 100%; 
  height: 100%;
}

.modal-generic-container {
  display: flex;
  flex-direction: column; 
  align-items: center;
  width: 100%;
  height: 100%;
}