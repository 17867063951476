/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

 @import 'Editor/_wickbrand.scss';

#project-settings-modal-title {
  font-size: 18px;
  color: $editor-modal-text-gray;
  font-weight: bold; 
  text-align: center;
}

.project-settings-property-label {
  color: $editor-modal-text-gray; 
  margin-bottom: 0;
}

.project-settings-split {
  padding-top: 150%;
  padding-left: 5px; 
  padding-right: 5px;
  color: $editor-modal-text-gray;
}

.project-setting-container {
  margin-top: 8px; 
}

.project-settings-size-input-container {
  display: flex;
  flex-direction: row;
}

.project-settings-size-input-container.mobile {
  margin-bottom: 4px;
}

.mobile-size {
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
}

#project-settings-modal-interior-content {
  display: flex;
  flex-direction: column;
  width: 100%; 
  height: 100%;
  justify-content: center;
  align-items: center;
}
 
 #project-settings-modal-footer {
   position: absolute;
   bottom: 18px;
   right: 18px;
   width: calc(100% - 40px); 
   height: 28px;
   margin-top: 25px;
   display: flex;
   flex-direction: row; 
   align-items: right;
   justify-content: right;
 }

 .project-settings-property-container {
  height: 30px; 
  display: flex; 
  flex-direction: row;
 }

 .project-settings-modal-cancel {
   width: 80px; 
   height: 100%; 
   margin-left: auto;
 }
 .project-settings-modal-cancel.mobile {
    width: calc(50% - 8px);
 }

 .project-settings-modal-button-text {
   margin-bottom: auto;
 }
 
 .project-settings-modal-accept {
   width: 80px;
   height: 100%; 
   margin-left: 8px;
 } 
 .project-settings-modal-accept.mobile {
   width: calc(50% - 8px);
 }
 
.project-settings-modal-row {
  display: flex;
  flex-direction: row; 
}

.project-settings-modal-body {
  display: flex;
  flex-direction: column;
}

.project-setting-element {
  width: 47.5%;
  margin-right: 5%;
  margin-top: 15px; 
}
.project-setting-element.mobile {
  width: 100%;
}

.project-setting-element:last-child {
  margin-right: 0;
}

.project-settings-modal-preset-text {
  color: white;
}
.project-settings-modal-preset-text.hover {
  color: $wick-green-text;
}
.project-settings-modal-preset-text.selected {
  color: $wick-green-text; 
}

.project-settings-modal-preset {
  height: 100%;
  width: 24%;
  margin-right: 1.25%;
  background-color: $editor-modal-gray-secondary;
  color: $editor-modal-text-gray;
  border-radius: 2px; 
  line-height: 50px;
  vertical-align: center;
  text-align: center; 
  font-size: 20px;
  cursor: pointer;  
  -webkit-transition-duration: .4s; /* Safari */
  transition-duration: .4s;
}

.project-settings-modal-preset:hover {
  background-color: $wick-green-light; 
  color: $wick-green-text; 
}

.project-settings-modal-preset.selected {
  background-color: $wick-green;
  color: $wick-green-text; 
}

.project-settings-modal-preset:last-child {
  margin-right: 0;
}

.project-settings-presets-container {
  height: 50px;
  width: 100%;
}

.project-settings-presets-body-container {
  display: flex;
  flex-direction: row;
}

.preset-boxes {
  width: 100%;
  height: 100%; 
  display: flex;
  flex-direction: row;
}

#projectWidth {
  width: 50%;
}

#projectHeight {
  width: 50%;
}