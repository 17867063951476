@import url(https://fonts.googleapis.com/css?family=Abel);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface);
@import url(https://fonts.googleapis.com/css?family=Allerta);
@import url(https://fonts.googleapis.com/css?family=Allerta+Stencil);
@import url(https://fonts.googleapis.com/css?family=Almendra+SC);
@import url(https://fonts.googleapis.com/css?family=Amatic+SC);
@import url(https://fonts.googleapis.com/css?family=Annie+Use+Your+Telescope);
@import url(https://fonts.googleapis.com/css?family=Antic+Didone);
@import url(https://fonts.googleapis.com/css?family=Antic+Slab);
@import url(https://fonts.googleapis.com/css?family=Anton);
@import url(https://fonts.googleapis.com/css?family=Architects+Daughter);
@import url(https://fonts.googleapis.com/css?family=Archivo+Narrow);
@import url(https://fonts.googleapis.com/css?family=Arimo);
@import url(https://fonts.googleapis.com/css?family=Arvo);
@import url(https://fonts.googleapis.com/css?family=Assistant);
@import url(https://fonts.googleapis.com/css?family=Average);
@import url(https://fonts.googleapis.com/css?family=Balthazar);
@import url(https://fonts.googleapis.com/css?family=Belgrano);
@import url(https://fonts.googleapis.com/css?family=Belleza);
@import url(https://fonts.googleapis.com/css?family=Bitter);
@import url(https://fonts.googleapis.com/css?family=Boogaloo);
@import url(https://fonts.googleapis.com/css?family=Bubbler+One);
@import url(https://fonts.googleapis.com/css?family=Cabin);
@import url(https://fonts.googleapis.com/css?family=Cagliostro);
@import url(https://fonts.googleapis.com/css?family=Cairo);
@import url(https://fonts.googleapis.com/css?family=Cambo);
@import url(https://fonts.googleapis.com/css?family=Candal);
@import url(https://fonts.googleapis.com/css?family=Catamaran);
@import url(https://fonts.googleapis.com/css?family=Ceviche+One);
@import url(https://fonts.googleapis.com/css?family=Chela+One);
@import url(https://fonts.googleapis.com/css?family=Cinzel);
@import url(https://fonts.googleapis.com/css?family=Contrail+One);
@import url(https://fonts.googleapis.com/css?family=Convergence);
@import url(https://fonts.googleapis.com/css?family=Crimson+Text);
@import url(https://fonts.googleapis.com/css?family=Damion);
@import url(https://fonts.googleapis.com/css?family=Dancing+Script);
@import url(https://fonts.googleapis.com/css?family=Domine);
@import url(https://fonts.googleapis.com/css?family=Dorsa);
@import url(https://fonts.googleapis.com/css?family=Dosis);
@import url(https://fonts.googleapis.com/css?family=Erica+One);
@import url(https://fonts.googleapis.com/css?family=Euphoria+Script);
@import url(https://fonts.googleapis.com/css?family=Fauna+One);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans+Condensed);
@import url(https://fonts.googleapis.com/css?family=Fjalla+One);
@import url(https://fonts.googleapis.com/css?family=Francois+One);
@import url(https://fonts.googleapis.com/css?family=Fresca);
@import url(https://fonts.googleapis.com/css?family=Fugaz+One);
@import url(https://fonts.googleapis.com/css?family=Gafata);
@import url(https://fonts.googleapis.com/css?family=Galdeano);
@import url(https://fonts.googleapis.com/css?family=Geostar+Fill);
@import url(https://fonts.googleapis.com/css?family=Germania+One);
@import url(https://fonts.googleapis.com/css?family=Gilda+Display);
@import url(https://fonts.googleapis.com/css?family=Gloria+Hallelujah);
@import url(https://fonts.googleapis.com/css?family=Goblin+One);
@import url(https://fonts.googleapis.com/css?family=Gochi+Hand);
@import url(https://fonts.googleapis.com/css?family=Graduate);
@import url(https://fonts.googleapis.com/css?family=Habibi);
@import url(https://fonts.googleapis.com/css?family=Hind+Madurai);
@import url(https://fonts.googleapis.com/css?family=Hind+Siliguri);
@import url(https://fonts.googleapis.com/css?family=Holtwood+One+SC);
@import url(https://fonts.googleapis.com/css?family=Homenaje);
@import url(https://fonts.googleapis.com/css?family=Iceberg);
@import url(https://fonts.googleapis.com/css?family=Iceland);
@import url(https://fonts.googleapis.com/css?family=Imprima);
@import url(https://fonts.googleapis.com/css?family=Inconsolata);
@import url(https://fonts.googleapis.com/css?family=Inder);
@import url(https://fonts.googleapis.com/css?family=Indie+Flower);
@import url(https://fonts.googleapis.com/css?family=Italiana);
@import url(https://fonts.googleapis.com/css?family=Julee);
@import url(https://fonts.googleapis.com/css?family=Kanit);
@import url(https://fonts.googleapis.com/css?family=Keania+One);
@import url(https://fonts.googleapis.com/css?family=Kite+One);
@import url(https://fonts.googleapis.com/css?family=Knewave);
@import url(https://fonts.googleapis.com/css?family=Kotta+One);
@import url(https://fonts.googleapis.com/css?family=Krona+One);
@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Lemon);
@import url(https://fonts.googleapis.com/css?family=Libre+Barcode+128);
@import url(https://fonts.googleapis.com/css?family=Libre+Barcode+39);
@import url(https://fonts.googleapis.com/css?family=Libre+Barcode+39+Extended);
@import url(https://fonts.googleapis.com/css?family=Libre+Barcode+39+Extended+Text);
@import url(https://fonts.googleapis.com/css?family=Libre+Barcode+39+Text);
@import url(https://fonts.googleapis.com/css?family=Libre+Franklin);
@import url(https://fonts.googleapis.com/css?family=Lilita+One);
@import url(https://fonts.googleapis.com/css?family=Lily+Script+One);
@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://fonts.googleapis.com/css?family=Loved+by+the+King);
@import url(https://fonts.googleapis.com/css?family=Lustria);
@import url(https://fonts.googleapis.com/css?family=Macondo+Swash+Caps);
@import url(https://fonts.googleapis.com/css?family=Marko+One);
@import url(https://fonts.googleapis.com/css?family=Mate+SC);
@import url(https://fonts.googleapis.com/css?family=Medula+One);
@import url(https://fonts.googleapis.com/css?family=Merienda+One);
@import url(https://fonts.googleapis.com/css?family=Montaga);
@import url(https://fonts.googleapis.com/css?family=Mr+Bedfort);
@import url(https://fonts.googleapis.com/css?family=Mukta);
@import url(https://fonts.googleapis.com/css?family=News+Cycle);
@import url(https://fonts.googleapis.com/css?family=Nothing+You+Could+Do);
@import url(https://fonts.googleapis.com/css?family=Noticia+Text);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans);
@import url(https://fonts.googleapis.com/css?family=Numans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Offside);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Overlock+SC);
@import url(https://fonts.googleapis.com/css?family=Pacifico);
@import url(https://fonts.googleapis.com/css?family=Passero+One);
@import url(https://fonts.googleapis.com/css?family=Pathway+Gothic+One);
@import url(https://fonts.googleapis.com/css?family=Patua+One);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Plaster);
@import url(https://fonts.googleapis.com/css?family=Play);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://fonts.googleapis.com/css?family=Poller+One);
@import url(https://fonts.googleapis.com/css?family=Pompiere);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Port+Lligat+Sans);
@import url(https://fonts.googleapis.com/css?family=Port+Lligat+Slab);
@import url(https://fonts.googleapis.com/css?family=Prociono);
@import url(https://fonts.googleapis.com/css?family=Questrial);
@import url(https://fonts.googleapis.com/css?family=Quicksand);
@import url(https://fonts.googleapis.com/css?family=Raleway);
@import url(https://fonts.googleapis.com/css?family=Rammetto+One);
@import url(https://fonts.googleapis.com/css?family=Righteous);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono);
@import url(https://fonts.googleapis.com/css?family=Rochester);
@import url(https://fonts.googleapis.com/css?family=Ropa+Sans);
@import url(https://fonts.googleapis.com/css?family=Ruluko);
@import url(https://fonts.googleapis.com/css?family=Sail);
@import url(https://fonts.googleapis.com/css?family=Shadows+Into+Light);
@import url(https://fonts.googleapis.com/css?family=Shadows+Into+Light+Two);
@import url(https://fonts.googleapis.com/css?family=Slabo+27px);
@import url(https://fonts.googleapis.com/css?family=Slackey);
@import url(https://fonts.googleapis.com/css?family=Sofia);
@import url(https://fonts.googleapis.com/css?family=Source+Code+Pro);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(https://fonts.googleapis.com/css?family=Source+Serif+Pro);
@import url(https://fonts.googleapis.com/css?family=Spinnaker);
@import url(https://fonts.googleapis.com/css?family=Squada+One);
@import url(https://fonts.googleapis.com/css?family=Strait);
@import url(https://fonts.googleapis.com/css?family=Tauri);
@import url(https://fonts.googleapis.com/css?family=Telex);
@import url(https://fonts.googleapis.com/css?family=Text+Me+One);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web);
@import url(https://fonts.googleapis.com/css?family=Trykker);
@import url(https://fonts.googleapis.com/css?family=Tulpen+One);
@import url(https://fonts.googleapis.com/css?family=Ubuntu+Condensed);
@import url(https://fonts.googleapis.com/css?family=Unlock);
@import url(https://fonts.googleapis.com/css?family=Vampiro+One);
@import url(https://fonts.googleapis.com/css?family=Viga);
@import url(https://fonts.googleapis.com/css?family=Vollkorn);
@import url(https://fonts.googleapis.com/css?family=Voltaire);
@import url(https://fonts.googleapis.com/css?family=Wendy+One);
@import url(https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz);
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

body, html {
  touch-action: none;
  position: fixed;
}

html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

body {
  overflow: hidden;
  text-rendering: optimizeLegibility; }

.toolbox-container {
  height: 40px;
  transition-timing-function: ease-in-out;
  transition: width .5s, height .5s; }

.toolbox-container-medium {
  height: 80px; }

.toolbox-container-small {
  height: 80px; }

.editor-canvas-timeline-panel {
  height: calc(100% - 40px);
  transition-timing-function: ease-in-out;
  transition: width .5s, height .5s; }

.editor-canvas-timeline-panel-medium {
  height: calc(100% - 80px); }

.editor-canvas-timeline-panel-small {
  height: calc(100% - 80px); }

#editor {
  width: 100%;
  height: 100%;
  background-color: red; }

#menu-bar-container {
  width: 100%;
  height: 40px;
  background-color: green; }

#editor-body {
  width: 100%;
  height: calc(100% - 40px);
  background-color: blue;
  overflow: hidden; }

#flexible-container {
  height: calc(100% - 40px);
  width: 100%;
  background-color: #AAA;
  display: inline-block;
  position: absolute;
  top: 40px; }

.center-container {
  background-color: #ffaabb; }

.right-container {
  background-color: #aabbcc; }

.right-reflex-container {
  height: 100%;
  background-color: #aabbcc; }

.Toastify__toast {
  font-family: 'Nunito Sans';
  font-weight: 800;
  font-size: 24px;
  border-radius: 3px; }

.Toastify__toast button {
  font-family: 'Nunito Sans';
  font-weight: 800;
  font-size: 24px; }

.info-toast-background {
  background-color: #383434; }

.info-toast-background button {
  color: #FFFFFF; }

.info-toast-body {
  color: #FFFFFF; }

.error-toast-background {
  background-color: #AC1706; }

.error-toast-background button {
  color: #FFFFFF; }

.error-toast-body {
  color: #FFFFFF; }

.warning-toast-background {
  background-color: #FFE243; }

.warning-toast-background button {
  color: #000000; }

.warning-toast-body {
  color: #000000; }

.success-toast-background {
  background-color: #29F1A3; }

.success-toast-background button {
  color: #000000; }

.success-toast-body {
  color: #000000; }

.mobile-editor-body {
  border-right-width: calc(3px * 2);
  border-right-style: solid;
  border-right-color: #191919 !important; }

/* Basic re-flex panel/splitter styling */
.reflex-container {
  justify-content: flex-start;
  /* align items in Main Axis */
  align-items: stretch;
  /* align items in Cross Axis */
  align-content: stretch;
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  position: relative;
  height: 100%;
  width: 100%; }

.reflex-container.horizontal {
  flex-direction: column; }

.reflex-container.vertical {
  flex-direction: row; }

.reflex-container > .reflex-element {
  position: relative;
  overflow: auto;
  height: 100%;
  width: 100%; }

.reflex-container.reflex-resizing > .reflex-element {
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.reflex-container > .reflex-element > .reflex-size-aware {
  height: 100%;
  width: 100%; }

.reflex-container > .reflex-splitter {
  background-color: #191919;
  z-index: 0; }

.reflex-container > .reflex-splitter.active,
.reflex-container > .reflex-splitter:hover {
  background-color: white;
  transition: all 1s ease; }

.horizontal > .reflex-splitter {
  border-bottom: 3px solid #191919 !important;
  border-top: 3px solid #191919 !important;
  cursor: row-resize;
  width: 100%;
  height: 3px; }

.reflex-element.horizontal .reflex-handle {
  cursor: row-resize;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.reflex-container.horizontal > .reflex-splitter:hover,
.reflex-container.horizontal > .reflex-splitter.active {
  border-bottom: 3px solid white !important;
  border-top: 3px solid white !important; }

.reflex-container.vertical > .reflex-splitter {
  border-right: 3px solid #191919 !important;
  border-left: 3px solid #191919 !important;
  cursor: col-resize;
  height: 100%;
  width: 3px; }

.reflex-element.vertical .reflex-handle {
  cursor: col-resize;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.reflex-container.vertical > .reflex-splitter:hover,
.reflex-container.vertical > .reflex-splitter.active {
  border-right: 3px solid white !important;
  border-left: 3px solid white !important; }

.reflex-container > .reflex-splitter.reflex-thin {
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  opacity: 0.2;
  z-index: 0; }

.reflex-container > .reflex-splitter.reflex-thin.active
.reflex-container > .reflex-splitter.reflex-thin:hover {
  transition: all 1.5s ease;
  opacity: 0.5; }

.reflex-container.horizontal > .reflex-splitter.reflex-thin {
  border-bottom: 3px solid #191919 !important;
  border-top: 3px solid #191919 !important;
  height: 3px;
  cursor: row-resize;
  margin: -3px 0;
  width: 100%; }

.reflex-container.horizontal > .reflex-splitter.reflex-thin.active,
.reflex-container.horizontal > .reflex-splitter.reflex-thin:hover {
  border-bottom: 3px solid white !important;
  border-top: 3px solid white !important; }

.reflex-container.vertical > .reflex-splitter.reflex-thin {
  border-right: 3px solid #191919 !important;
  border-left: 3px solid #191919 !important;
  width: 3px !important;
  cursor: col-resize;
  margin: -3px;
  height: 100%; }

.reflex-container.vertical > .reflex-splitter.reflex-thin.active,
.reflex-container.vertical > .reflex-splitter.reflex-thin:hover {
  border-right: 3px solid white !important;
  border-left: 3px solid white !important; }

.mobile-reflex-splitter {
  height: 15px !important;
  border: 3px solid black !important; }


.theme-default {
    --border-radius-primary: 8px;
    --border-radius-secondary: 4px;

    /* Main Colors */
    --wick-primary: #303030;
    --wick-secondary: #3B3B3B;
    --wick-tertiary: #706d6d;
    --wick-disabled: #666666;


    /* Text Colors */
    --wick-text-primary: white;
    --wick-text-secondary: #BDBDBD;
    --wick-text-tertiary: #919191;
    --wick-text-disabled: white;
    --wick-text-event: black;

    /* Events */
    --wick-event-mouse: #01C094;
    --wick-event-keyboard: #E9AA02;
    --wick-event-timeline: #00A1E0;

    --wick-event-mouse-selected: #29F1A3;
    --wick-event-keyboard-selected: #FFE243;
    --wick-event-timeline-selected: #05B8FF;

    /* Theme Colors */
    --wick-red: #FF7878;
    --wick-red-light: #ff5c5c;
    
    --wick-green: #09F197;
    --wick-green-light: #19ffa7;

    --wick-yellow: #F0CC00;
    --wick-yellow-light: #ffde20;

    --wick-light-blue: #92D8FF;
    --wick-light-blue-light: #74ceff;

    --wick-purple: #C987FA;
    --wick-purple-light: #d49bff;

    --wick-teal: #26DED2;
    --wick-teal-light: #43fff2;

    --wick-pink: #FF66F0;
    --wick-pink-light: #ff80f2;

    --wick-error: rgba(255, 0, 0, .6);


    /* Shadows */
    --wick-box-shadow: 2px 2px 8px var(--wick-primary); 
}
/* Defines how theme colors can be used around editor */

/* Events */
.we-event.Mouse {
    background-color: var(--wick-event-mouse);
}

.we-event.Mouse.selected {
    background-color: var(--wick-event-mouse-selected);
}

.we-event.Keyboard {
    background-color: var(--wick-event-keyboard);
}

.we-event.Keyboard.selected {
    background-color: var(--wick-event-keyboard-selected);
}

.we-event.Timeline {
    background-color: var(--wick-event-timeline);
}

.we-event.Timeline.selected {
    background-color: var(--wick-event-timeline-selected);
}

/* Code Reference */
.we-code.Timeline {
    background-color: var(--wick-red);
}

.we-code.Timeline:hover {
    background-color: var(--wick-red-light);
}

.we-code.Object {
    background-color: var(--wick-green);
}

.we-code.Object:hover {
    background-color: var(--wick-green-light);
}

.we-code.Input {
    background-color: var(--wick-yellow);
}

.we-code.Input {
    background-color: var(--wick-yellow-light);
}

.we-code.Project {
    background-color: var(--wick-light-blue);
}

.we-code.Project:hover {
    background-color: var(--wick-light-blue-light);
}

.we-code.Random {
    background-color: var(--wick-purple);
}

.we-code.Random:hover {
    background-color: var(--wick-purple-light);
}

.we-code.Sound {
    background-color: var(--wick-teal);
}

.we-code.Sound:hover {
    background-color: var(--wick-teal-light);
}

.we-code.Event {
    background-color: var(--wick-pink);
}

.we-code.Event:hover {
    background-color: var(--wick-pink-light);
}
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.docked-panel {
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 100%;
  overflow: hidden; }

.docked-panel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: .5;
  z-index: 1; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

#wick-canvas-container {
  width: 100%;
  height: 100%;
  background-color: #bbb;
  position: relative; }

#canvas-container-wrapper {
  border-left: 4px solid #191919; }

canvas[resize] {
  width: 100%;
  height: 100%; }

.drag-drop-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.2;
  z-index: 1;
  background-color: #EAEAEA; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.inspector {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.inspector-body {
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.inspector-body:hover {
  overflow-y: auto; }

.input-divider {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Nunito Sans';
  display: flex;
  align-items: center;
  justify-content: center; }

.inspector-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: flex;
  flex-direction: column;
  align-items: center; }

.select-inspector {
  height: 20px; }

.inspector-action-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px; }

.font-selector-Abel {
  font-family: 'Abel','Arial'; }

.font-selector-Abril-Fatface {
  font-family: 'Abril Fatface','Arial'; }

.font-selector-Allerta {
  font-family: 'Allerta','Arial'; }

.font-selector-Allerta-Stencil {
  font-family: 'Allerta Stencil','Arial'; }

.font-selector-Almendra-SC {
  font-family: 'Almendra SC','Arial'; }

.font-selector-Amatic-SC {
  font-family: 'Amatic SC','Arial'; }

.font-selector-Annie-Use-Your-Telescope {
  font-family: 'Annie Use Your Telescope','Arial'; }

.font-selector-Antic-Didone {
  font-family: 'Antic Didone','Arial'; }

.font-selector-Antic-Slab {
  font-family: 'Antic Slab','Arial'; }

.font-selector-Anton {
  font-family: 'Anton','Arial'; }

.font-selector-Architects-Daughter {
  font-family: 'Architects Daughter','Arial'; }

.font-selector-Archivo-Narrow {
  font-family: 'Archivo Narrow','Arial'; }

.font-selector-Arimo {
  font-family: 'Arimo','Arial'; }

.font-selector-Arvo {
  font-family: 'Arvo','Arial'; }

.font-selector-Assistant {
  font-family: 'Assistant','Arial'; }

.font-selector-Average {
  font-family: 'Average','Arial'; }

.font-selector-Balthazar {
  font-family: 'Balthazar','Arial'; }

.font-selector-Belgrano {
  font-family: 'Belgrano','Arial'; }

.font-selector-Belleza {
  font-family: 'Belleza','Arial'; }

.font-selector-Bitter {
  font-family: 'Bitter','Arial'; }

.font-selector-Boogaloo {
  font-family: 'Boogaloo','Arial'; }

.font-selector-Bubbler-One {
  font-family: 'Bubbler One','Arial'; }

.font-selector-Cabin {
  font-family: 'Cabin','Arial'; }

.font-selector-Cagliostro {
  font-family: 'Cagliostro','Arial'; }

.font-selector-Cairo {
  font-family: 'Cairo','Arial'; }

.font-selector-Cambo {
  font-family: 'Cambo','Arial'; }

.font-selector-Candal {
  font-family: 'Candal','Arial'; }

.font-selector-Catamaran {
  font-family: 'Catamaran','Arial'; }

.font-selector-Ceviche-One {
  font-family: 'Ceviche One','Arial'; }

.font-selector-Chela-One {
  font-family: 'Chela One','Arial'; }

.font-selector-Cinzel {
  font-family: 'Cinzel','Arial'; }

.font-selector-Contrail-One {
  font-family: 'Contrail One','Arial'; }

.font-selector-Convergence {
  font-family: 'Convergence','Arial'; }

.font-selector-Crimson-Text {
  font-family: 'Crimson Text','Arial'; }

.font-selector-Damion {
  font-family: 'Damion','Arial'; }

.font-selector-Dancing-Script {
  font-family: 'Dancing Script','Arial'; }

.font-selector-Domine {
  font-family: 'Domine','Arial'; }

.font-selector-Dorsa {
  font-family: 'Dorsa','Arial'; }

.font-selector-Dosis {
  font-family: 'Dosis','Arial'; }

.font-selector-Erica-One {
  font-family: 'Erica One','Arial'; }

.font-selector-Euphoria-Script {
  font-family: 'Euphoria Script','Arial'; }

.font-selector-Fauna-One {
  font-family: 'Fauna One','Arial'; }

.font-selector-Fira-Sans-Condensed {
  font-family: 'Fira Sans Condensed','Arial'; }

.font-selector-Fjalla-One {
  font-family: 'Fjalla One','Arial'; }

.font-selector-Francois-One {
  font-family: 'Francois One','Arial'; }

.font-selector-Fresca {
  font-family: 'Fresca','Arial'; }

.font-selector-Fugaz-One {
  font-family: 'Fugaz One','Arial'; }

.font-selector-Gafata {
  font-family: 'Gafata','Arial'; }

.font-selector-Galdeano {
  font-family: 'Galdeano','Arial'; }

.font-selector-Geostar-Fill {
  font-family: 'Geostar Fill','Arial'; }

.font-selector-Germania-One {
  font-family: 'Germania One','Arial'; }

.font-selector-Gilda-Display {
  font-family: 'Gilda Display','Arial'; }

.font-selector-Gloria-Hallelujah {
  font-family: 'Gloria Hallelujah','Arial'; }

.font-selector-Goblin-One {
  font-family: 'Goblin One','Arial'; }

.font-selector-Gochi-Hand {
  font-family: 'Gochi Hand','Arial'; }

.font-selector-Graduate {
  font-family: 'Graduate','Arial'; }

.font-selector-Habibi {
  font-family: 'Habibi','Arial'; }

.font-selector-Hind-Madurai {
  font-family: 'Hind Madurai','Arial'; }

.font-selector-Hind-Siliguri {
  font-family: 'Hind Siliguri','Arial'; }

.font-selector-Holtwood-One-SC {
  font-family: 'Holtwood One SC','Arial'; }

.font-selector-Homenaje {
  font-family: 'Homenaje','Arial'; }

.font-selector-Iceberg {
  font-family: 'Iceberg','Arial'; }

.font-selector-Iceland {
  font-family: 'Iceland','Arial'; }

.font-selector-Imprima {
  font-family: 'Imprima','Arial'; }

.font-selector-Inconsolata {
  font-family: 'Inconsolata','Arial'; }

.font-selector-Inder {
  font-family: 'Inder','Arial'; }

.font-selector-Indie-Flower {
  font-family: 'Indie Flower','Arial'; }

.font-selector-Italiana {
  font-family: 'Italiana','Arial'; }

.font-selector-Julee {
  font-family: 'Julee','Arial'; }

.font-selector-Kanit {
  font-family: 'Kanit','Arial'; }

.font-selector-Keania-One {
  font-family: 'Keania One','Arial'; }

.font-selector-Kite-One {
  font-family: 'Kite One','Arial'; }

.font-selector-Knewave {
  font-family: 'Knewave','Arial'; }

.font-selector-Kotta-One {
  font-family: 'Kotta One','Arial'; }

.font-selector-Krona-One {
  font-family: 'Krona One','Arial'; }

.font-selector-Lato {
  font-family: 'Lato','Arial'; }

.font-selector-Lemon {
  font-family: 'Lemon','Arial'; }

.font-selector-Libre-Barcode-128 {
  font-family: 'Libre Barcode 128','Arial'; }

.font-selector-Libre-Barcode-39 {
  font-family: 'Libre Barcode 39','Arial'; }

.font-selector-Libre-Barcode-39-Extended {
  font-family: 'Libre Barcode 39 Extended','Arial'; }

.font-selector-Libre-Barcode-39-Extended-Text {
  font-family: 'Libre Barcode 39 Extended Text','Arial'; }

.font-selector-Libre-Barcode-39-Text {
  font-family: 'Libre Barcode 39 Text','Arial'; }

.font-selector-Libre-Franklin {
  font-family: 'Libre Franklin','Arial'; }

.font-selector-Lilita-One {
  font-family: 'Lilita One','Arial'; }

.font-selector-Lily-Script-One {
  font-family: 'Lily Script One','Arial'; }

.font-selector-Lobster {
  font-family: 'Lobster','Arial'; }

.font-selector-Loved-by-the-King {
  font-family: 'Loved by the King','Arial'; }

.font-selector-Lustria {
  font-family: 'Lustria','Arial'; }

.font-selector-Macondo-Swash-Caps {
  font-family: 'Macondo Swash Caps','Arial'; }

.font-selector-Marko-One {
  font-family: 'Marko One','Arial'; }

.font-selector-Mate-SC {
  font-family: 'Mate SC','Arial'; }

.font-selector-Medula-One {
  font-family: 'Medula One','Arial'; }

.font-selector-Merienda-One {
  font-family: 'Merienda One','Arial'; }

.font-selector-Montaga {
  font-family: 'Montaga','Arial'; }

.font-selector-Mr-Bedfort {
  font-family: 'Mr Bedfort','Arial'; }

.font-selector-Mukta {
  font-family: 'Mukta','Arial'; }

.font-selector-News-Cycle {
  font-family: 'News Cycle','Arial'; }

.font-selector-Nothing-You-Could-Do {
  font-family: 'Nothing You Could Do','Arial'; }

.font-selector-Noticia-Text {
  font-family: 'Noticia Text','Arial'; }

.font-selector-Noto-Sans {
  font-family: 'Noto Sans','Arial'; }

.font-selector-Numans {
  font-family: 'Numans','Arial'; }

.font-selector-Nunito {
  font-family: 'Nunito','Arial'; }

.font-selector-Nunito-Sans {
  font-family: 'Nunito Sans','Arial'; }

.font-selector-Offside {
  font-family: 'Offside','Arial'; }

.font-selector-Open-Sans {
  font-family: 'Open Sans','Arial'; }

.font-selector-Oswald {
  font-family: 'Oswald','Arial'; }

.font-selector-Overlock-SC {
  font-family: 'Overlock SC','Arial'; }

.font-selector-Pacifico {
  font-family: 'Pacifico','Arial'; }

.font-selector-Passero-One {
  font-family: 'Passero One','Arial'; }

.font-selector-Pathway-Gothic-One {
  font-family: 'Pathway Gothic One','Arial'; }

.font-selector-Patua-One {
  font-family: 'Patua One','Arial'; }

.font-selector-Permanent-Marker {
  font-family: 'Permanent Marker','Arial'; }

.font-selector-Plaster {
  font-family: 'Plaster','Arial'; }

.font-selector-Play {
  font-family: 'Play','Arial'; }

.font-selector-Playfair-Display {
  font-family: 'Playfair Display','Arial'; }

.font-selector-Poller-One {
  font-family: 'Poller One','Arial'; }

.font-selector-Pompiere {
  font-family: 'Pompiere','Arial'; }

.font-selector-Poppins {
  font-family: 'Poppins','Arial'; }

.font-selector-Port-Lligat-Sans {
  font-family: 'Port Lligat Sans','Arial'; }

.font-selector-Port-Lligat-Slab {
  font-family: 'Port Lligat Slab','Arial'; }

.font-selector-Prociono {
  font-family: 'Prociono','Arial'; }

.font-selector-Questrial {
  font-family: 'Questrial','Arial'; }

.font-selector-Quicksand {
  font-family: 'Quicksand','Arial'; }

.font-selector-Raleway {
  font-family: 'Raleway','Arial'; }

.font-selector-Rammetto-One {
  font-family: 'Rammetto One','Arial'; }

.font-selector-Righteous {
  font-family: 'Righteous','Arial'; }

.font-selector-Roboto {
  font-family: 'Roboto','Arial'; }

.font-selector-Roboto-Mono {
  font-family: 'Roboto Mono','Arial'; }

.font-selector-Rochester {
  font-family: 'Rochester','Arial'; }

.font-selector-Ropa-Sans {
  font-family: 'Ropa Sans','Arial'; }

.font-selector-Ruluko {
  font-family: 'Ruluko','Arial'; }

.font-selector-Sail {
  font-family: 'Sail','Arial'; }

.font-selector-Shadows-Into-Light {
  font-family: 'Shadows Into Light','Arial'; }

.font-selector-Shadows-Into-Light-Two {
  font-family: 'Shadows Into Light Two','Arial'; }

.font-selector-Slabo-27px {
  font-family: 'Slabo 27px','Arial'; }

.font-selector-Slackey {
  font-family: 'Slackey','Arial'; }

.font-selector-Sofia {
  font-family: 'Sofia','Arial'; }

.font-selector-Source-Code-Pro {
  font-family: 'Source Code Pro','Arial'; }

.font-selector-Source-Sans-Pro {
  font-family: 'Source Sans Pro','Arial'; }

.font-selector-Source-Serif-Pro {
  font-family: 'Source Serif Pro','Arial'; }

.font-selector-Spinnaker {
  font-family: 'Spinnaker','Arial'; }

.font-selector-Squada-One {
  font-family: 'Squada One','Arial'; }

.font-selector-Strait {
  font-family: 'Strait','Arial'; }

.font-selector-Tauri {
  font-family: 'Tauri','Arial'; }

.font-selector-Telex {
  font-family: 'Telex','Arial'; }

.font-selector-Text-Me-One {
  font-family: 'Text Me One','Arial'; }

.font-selector-Titillium-Web {
  font-family: 'Titillium Web','Arial'; }

.font-selector-Trykker {
  font-family: 'Trykker','Arial'; }

.font-selector-Tulpen-One {
  font-family: 'Tulpen One','Arial'; }

.font-selector-Ubuntu-Condensed {
  font-family: 'Ubuntu Condensed','Arial'; }

.font-selector-Unlock {
  font-family: 'Unlock','Arial'; }

.font-selector-Vampiro-One {
  font-family: 'Vampiro One','Arial'; }

.font-selector-Viga {
  font-family: 'Viga','Arial'; }

.font-selector-Vollkorn {
  font-family: 'Vollkorn','Arial'; }

.font-selector-Voltaire {
  font-family: 'Voltaire','Arial'; }

.font-selector-Wendy-One {
  font-family: 'Wendy One','Arial'; }

.font-selector-Yanone-Kaffeesatz {
  font-family: 'Yanone Kaffeesatz','Arial'; }

.existing-font {
  background-color: #D3F8F4; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.inspector {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.inspector-body {
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.inspector-body:hover {
  overflow-y: auto; }

.input-divider {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Nunito Sans';
  display: flex;
  align-items: center;
  justify-content: center; }

.inspector-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: flex;
  flex-direction: column;
  align-items: center; }

.select-inspector {
  height: 20px; }

.inspector-action-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px; }

.inspector-title {
  height: 36px;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  display: flex;
  align-items: center; }

.inspector-title-container {
  box-shadow: 0px 2px 4px black;
  position: relative; }

.inspector-title-name {
  font-size: 22px;
  color: white;
  font-family: 'Nunito Sans';
  display: inline-block; }

.inspector-title-selection-type {
  height: 80%;
  display: inline-flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #BDBDBD;
  margin-left: 8px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.inspector {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.inspector-body {
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.inspector-body:hover {
  overflow-y: auto; }

.input-divider {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Nunito Sans';
  display: flex;
  align-items: center;
  justify-content: center; }

.inspector-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: flex;
  flex-direction: column;
  align-items: center; }

.select-inspector {
  height: 20px; }

.inspector-action-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px; }

.inspector-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 26px;
  margin-bottom: 4px; }

.inspector-row:last-child {
  margin-bottom: 0px; }

.inspector-row-identifier {
  display: flex;
  flex-direction: column;
  width: 30%;
  max-width: 30%;
  height: 100%;
  color: white;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  text-align: right;
  padding-right: 1.5%;
  padding-left: 1.5%;
  margin-top: 3px; }

.inspector-row-identifier:first-child {
  padding-left: 0; }

.inspector-small-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  min-width: 30px;
  height: 90%;
  padding-left: 1.5%; }

.inspector-small-input-container:last-child {
  padding-right: 0; }

.inspector-medium-input-container {
  width: calc(100% - 30% - 20%);
  padding-right: 0;
  padding-left: 1.5%; }

.inspector-large-input-container {
  width: calc(100% - 30%);
  padding-right: 0;
  padding-left: 1.5%; }

.inspector-input-element {
  display: inline-block;
  height: 100%;
  width: 100%; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.wick-input-container {
  width: 100%;
  height: 100%;
  display: flex; }

.wick-input {
  padding: 2px;
  padding-left: 4px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: none;
  background-color: #4F4F4F;
  color: white; }

.wick-input.invalid {
  border-left: 3px solid #F86868 !important; }

.wick-input-updating {
  border: 3px solid #FFC835 !important; }

.wick-input.read-only {
  background-color: gray; }

.wick-slider {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin-top: auto; }

.wick-checkbox {
  min-width: 20px;
  min-height: 20px; }

.wick-checkbox:hover {
  cursor: pointer; }

.wick-checkbox-label {
  margin: 0;
  margin-right: 4px;
  color: white; }

.wick-checkbox-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center; }

.wick-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 2px;
  cursor: pointer;
  padding: 2px; }

.wick-slider {
  width: 100%;
  display: flex;
  align-items: center; }

.wick-select {
  width: 100%;
  height: 100%; }

.wick-select-container {
  font-size: 15px;
  height: 26px; }

.wick-select-inner {
  font-size: 20px; }

.wick-select-control {
  padding: 0 !important;
  padding-left: 2px !important;
  display: flex;
  align-items: center;
  min-height: 26px; }

.wick-select-menu {
  font-size: 10px;
  background-color: #EAEAEA;
  color: #1D1921; }

.Dropdown-option {
  padding: 2px !important;
  white-space: nowrap;
  background-color: #EAEAEA;
  color: #1D1921; }

.Dropdown-option.is-selected {
  background-color: #29F1A3 !important;
  color: #1D1921 !important; }

.Dropdown-placeholder {
  white-space: nowrap;
  max-width: 110px; }

.wick-tooltip {
  max-width: 220px !important;
  opacity: 1 !important;
  background-color: #265A87 !important;
  font-size: 14px !important; }

.wick-input-select {
  width: 100%;
  color: "black";
  font-size: "16px";
  background-color: "white";
  display: "flex";
  height: 100% !important;
  border-radius: 4px !important; }

.wick-input-select__control {
  width: 100%;
  height: 100%;
  min-height: auto !important;
  display: flex;
  align-items: center;
  background-color: #FFFFFF; }

.wick-input-select__menu {
  margin-top: 0px !important; }

.wick-input-select__value-container {
  padding: 0px; }

.wick-input-select__dropdown-indicator {
  padding: 0px !important;
  padding-left: 4px !important;
  padding-right: 4px !important; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.img-tool-icon {
  height: 100%;
  display: flex;
  align-items: center;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  pointer-events: none; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.action-button {
  width: 100%;
  height: 100%;
  touch-action: manipulation;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.action-button-single-icon {
  margin: 0 auto;
  padding: 1px; }

.action-button-text {
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.action-button-dropdown-icon-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.dropdown-extra-icon {
  height: 6px;
  margin-top: 1px; }

.dropdown-action-button-icon {
  height: 18px !important; }

.action-button-icon-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-height: 100%; }

.action-button-text-icon.mobile-asset-library-icon {
  height: 20px;
  width: auto;
  margin-right: 8px; }

.action-button-text-icon {
  height: 18px;
  margin-right: 8px; }

.action-button-text {
  display: flex;
  justify-content: center;
  align-items: center; }

.action-button-blue {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #101DA5;
  color: white; }

body.hasHover .action-button-blue:hover {
  background-color: #2636E1;
  color: white; }

.action-button-blue:active {
  background-color: #010A6F;
  color: white; }

.action-button-blue.active-button {
  background-color: #010A6F;
  color: white; }

.action-button-sky {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #8DDED6;
  color: black; }

body.hasHover .action-button-sky:hover {
  background-color: #B4ECE6;
  color: black; }

.action-button-sky:active {
  background-color: #D3F8F4;
  color: black; }

.action-button-sky.active-button {
  background-color: #D3F8F4;
  color: black; }

.action-button-red {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #F86868;
  color: black; }

body.hasHover .action-button-red:hover {
  background-color: #FF8C8C;
  color: black; }

.action-button-red:active {
  background-color: #E61E07;
  color: black; }

.action-button-red.active-button {
  background-color: #E61E07;
  color: black; }

.action-button-dark-red {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #A30000;
  color: black; }

body.hasHover .action-button-dark-red:hover {
  background-color: #B30000;
  color: black; }

.action-button-dark-red:active {
  background-color: #940000;
  color: black; }

.action-button-dark-red.active-button {
  background-color: #940000;
  color: black; }

.action-button-active-green {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #01C094;
  color: black; }

body.hasHover .action-button-active-green:hover {
  background-color: #1EE29A; }

.action-button-active-green:active {
  background-color: #01C094; }

.action-button-active-green.active-button {
  background-color: #01C094; }

.action-button-green {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #1EE29A;
  color: #303030; }

body.hasHover .action-button-green:hover {
  background-color: #29F1A3;
  color: #303030; }

.action-button-green:active {
  background-color: #01C094;
  color: #303030; }

.action-button-green.active-button {
  background-color: #01C094;
  color: #303030; }

.action-button-yellow {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #FFC835;
  color: black; }

body.hasHover .action-button-yellow:hover {
  background-color: #FFE243;
  color: black; }

.action-button-yellow:active {
  background-color: #E9AA02;
  color: black; }

.action-button-yellow.active-button {
  background-color: #E9AA02;
  color: black; }

.action-button-tool {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #303030;
  color: black; }

body.hasHover .action-button-tool:hover {
  border: 2px solid #29F1A3;
  color: black; }

.action-button-tool:active {
  border: 2px solid #01C094;
  background-color: #3B3B3B;
  color: black; }

.action-button-tool.active-button {
  border: 2px solid #01C094;
  color: black; }

.action-button-menu {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #303030;
  color: #E0E0E0; }

body.hasHover .action-button-menu:hover {
  color: #FFFFFF; }

.action-button-menu:active {
  color: #FFFFFF; }

.action-button-menu.active-button {
  color: #FFFFFF; }

.action-button-menu img {
  width: auto; }

.action-button-menu.save {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #303030;
  color: #1EE29A;
  border: 1.5px solid #1EE29A;
  padding: 0 5px 0 5px; }

.action-button-tool-settings {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #3B3B3B;
  color: #BDBDBD; }

body.hasHover .action-button-tool-settings:hover {
  background-color: #4A4A4A;
  color: white; }

.action-button-tool-settings:active {
  background-color: #4A4A4A; }

.action-button-tool-settings.active-button {
  background-color: #4A4A4A; }

.action-button-upload {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #303030;
  color: white;
  border: 1.5px solid #CCCCCC;
  font-size: 16px; }

.action-button-upload img {
  width: 18px;
  height: 18px;
  padding: 0 2px 0 2px; }

body.hasHover .action-button-upload:hover {
  color: #01C094;
  border: 1.5px solid #29F1A3; }

.action-button-upload:active {
  color: #01C094;
  border: 1.5px solid #1EE29A; }

.action-button-upload.active-button {
  color: #01C094;
  border: 1.5px solid #29F1A3; }

.action-button-gray {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #4F4F4F;
  color: #ffffff;
  font-size: 16px; }

body.hasHover .action-button-gray:hover {
  background-color: #575757;
  color: #ffffff; }

.action-button-gray:active {
  background-color: #424242;
  color: #ffffff; }

.action-button-gray.active-button {
  background-color: #424242;
  color: #ffffff; }

.action-button-gray-green {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #4F4F4F;
  color: white;
  font-size: 16px; }

body.hasHover .action-button-gray-green:hover {
  background-color: #1EE29A;
  color: black; }

.action-button-gray-green:active {
  background-color: #01C094;
  color: black; }

.action-button-gray-green.active-button {
  background-color: #01C094;
  color: black; }

.action-button-error {
  /* Safari */
  transition-duration: 0.2s;
  background-color: transparent;
  color: white;
  font-size: 16px; }

body.hasHover .action-button-error:hover {
  background-color: #FF7867;
  color: white; }

.action-button-error:active {
  background-color: #8D564D;
  color: white; }

.action-button-error.active-button {
  background-color: #8D564D;
  color: white; }

.action-button-checkbox {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #3B3B3B;
  color: black; }

body.hasHover .action-button-checkbox:hover {
  border: 2px solid #29F1A3;
  color: black; }

.action-button-checkbox:active {
  border: 2px solid #01C094;
  color: black; }

.action-button-checkbox.active-button {
  border: 2px solid #01C094;
  color: black; }

.action-button-active-blue {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #00ADEF;
  color: black;
  font-size: 16px; }

body.hasHover .action-button-active-blue:hover {
  background-color: #00B9FF;
  color: black; }

.action-button-active-blue:active {
  background-color: #00709c;
  color: black; }

.action-button-active-blue.active-button {
  background-color: #00709c;
  color: black; }

.action-button-inspector {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #00ADEF;
  color: black;
  font-size: 16px; }

body.hasHover .action-button-inspector:hover {
  background-color: #00B9FF;
  color: black; }

.action-button-inspector:active {
  background-color: #00709c;
  color: black; }

.action-button-inspector.active-button {
  background-color: #00709c;
  color: black; }

.action-button-reference {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #353434;
  color: white;
  font-size: 16px; }

body.hasHover .action-button-reference:hover {
  color: #29F1A3; }

.action-button-reference:active {
  color: #29F1A3; }

.action-button-reference.active-button {
  color: #29F1A3; }

.action-button-script-name {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #303030;
  color: #CCCCCC;
  font-size: 16px; }

body.hasHover .action-button-script-name:hover {
  color: #FFFFFF; }

.action-button-script-name:active {
  color: #FFFFFF; }

.action-button-script-name.active-button {
  color: #FFFFFF; }

.action-button-script-name-text {
  text-align: left;
  vertical-align: center; }

.action-button-flame {
  /* Safari */
  transition-duration: 0.2s;
  background-color: #F66A37;
  color: black;
  font-size: 16px; }

body.hasHover .action-button-flame:hover {
  background-color: #FF7848;
  color: black; }

.action-button-flame:active {
  background-color: #E6460E;
  color: black; }

.action-button-flame.active-button {
  background-color: #E6460E;
  color: black; }

.action-button-support {
  /* Safari */
  transition-duration: 0.2s;
  background-color: transparent;
  border: 2px solid #E85B46;
  color: #e0e0e0;
  padding: 2px 6px 2px 6px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.action-button-support img {
  width: 18px;
  height: auto;
  order: 1; }

.action-button-support span {
  font-family: 'Nunito Sans';
  padding-left: 5px;
  order: 2; }

.action-button-support:hover {
  color: white; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.wick-color-picker {
  background-color: #303030;
  width: 220px;
  height: 300px;
  padding: 10px 10px 0px;
  border-radius: 4px; }

.wick-color-picker-header {
  height: 25px;
  display: flex; }

.wick-color-picker-saturation {
  width: 100%;
  height: 115px;
  position: relative;
  margin-top: 5px;
  border-radius: 2px;
  overflow: hidden; }

#btn-color-picker-close {
  margin-left: auto;
  width: 25px;
  height: 25px; }

#btn-color-picker-dropper {
  width: 25px;
  height: 25px;
  margin-right: 5px; }

.wick-color-picker-action-button {
  width: 30px;
  height: 25px;
  margin-right: 4px; }

.wick-color-picker-action-button.spacer {
  margin-right: auto; }

.wick-color-picker-control-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 5px; }

.wick-color-picker-control-bar {
  width: calc(220px - 2 * 10px - 2 * 25px - 2 * 5px);
  height: 45%;
  position: relative;
  margin-bottom: 2.5%;
  background-color: white; }

.wick-color-picker-color-block-container {
  position: relative;
  width: 25px;
  height: 25px;
  margin-left: 5px;
  background-color: white;
  border-radius: 2px; }

.wick-color-picker-swatches-container {
  height: 25px;
  display: flex;
  margin-top: 5px;
  flex-wrap: wrap;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 8px;
  margin: 5px -10px;
  padding: 5px 0px 0px 10px; }

.wick-color-picker-small-swatch {
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #222;
  margin: 0px 10px 10px 0px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==");
  background-size: 15px 15px; }

.column-swatch {
  width: 30px;
  height: 20px; }

.column-swatch:first-child {
  margin-bottom: 2px; }

.wick-swatch-picker-column {
  display: flex;
  flex-direction: column;
  margin: 0px 4px 4px 0px;
  border-radius: 2px;
  overflow: hidden; }

.wick-swatch-picker-book {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5px -10px;
  padding: 5px 0px 0px 10px; }

.wick-swatch-color-picker-body {
  display: flex;
  flex-direction: column; }

.wick-color-picker .flexbox-fix {
  margin-top: calc(5px + 4px) !important;
  padding-top: 0px !important; }

.wick-color-picker input {
  width: 100% !important;
  border-radius: 4px;
  background-color: #303030 !important;
  border: 3px solid #191919 !important;
  color: white !important;
  box-shadow: none !important;
  padding: 0px 0px 0px 2px !important; }

.wick-color-picker span {
  padding-bottom: 0px !important;
  color: white !important; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.btn-color-picker {
  display: flex;
  background: none;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  border: 4px solid #BDBDBD;
  box-sizing: border-box; }

.color-picker-control-div {
  display: flex;
  flex-direction: row; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.inspector {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.inspector-body {
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.inspector-body:hover {
  overflow-y: auto; }

.input-divider {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Nunito Sans';
  display: flex;
  align-items: center;
  justify-content: center; }

.inspector-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: flex;
  flex-direction: column;
  align-items: center; }

.select-inspector {
  height: 20px; }

.inspector-action-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px; }

.inspector-button {
  height: 40px;
  width: 100%; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.inspector {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.inspector-body {
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.inspector-body:hover {
  overflow-y: auto; }

.input-divider {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Nunito Sans';
  display: flex;
  align-items: center;
  justify-content: center; }

.inspector-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: flex;
  flex-direction: column;
  align-items: center; }

.select-inspector {
  height: 20px; }

.inspector-action-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px; }

.inspector-image-preview {
  height: 100px;
  width: auto; }

.inspector-image-preview-container {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px; }

.inspector-sound-preview-container {
  margin: 5px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.audio-player-container {
  background-color: #303030;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  padding-top: 5px;
  padding-left: 5px; }

.playbutton {
  display: inline-flex;
  flex-direction: column;
  width: 40px;
  height: 40px; }

.controls {
  display: inline-flex;
  flex-direction: column;
  width: calc(100% - 40px);
  height: 90%;
  vertical-align: top; }

.control {
  margin-left: auto;
  margin-right: auto;
  width: 90%; }

.info-text {
  color: white;
  text-align: center;
  overflow: hidden; }

.no-background {
  background: none; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.inspector {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.inspector-body {
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.inspector-body:hover {
  overflow-y: auto; }

.input-divider {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Nunito Sans';
  display: flex;
  align-items: center;
  justify-content: center; }

.inspector-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: flex;
  flex-direction: column;
  align-items: center; }

.select-inspector {
  height: 20px; }

.inspector-action-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px; }

.inspector-script-window-row-container {
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 3px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px; }

.inspector-script-window-container {
  width: 100%;
  height: 100px;
  background-color: #4A4A4A;
  border-radius: 3px; }

.inspector-script-window-container {
  height: 100%;
  padding-bottom: 5px; }

.inspector-script-window-header {
  width: 100%;
  height: 25px;
  background-color: #3B3B3B;
  color: #ffffff;
  text-align: center;
  vertical-align: center;
  border-radius: 3px 3px 0px 0px; }

.inspector-add-script-button {
  height: 25px;
  width: 100%; }

.inspector-script-window-row-name {
  width: calc(100% - 25px - 4px);
  height: 100%;
  background-color: #303030;
  color: white;
  border-radius: 3px;
  overflow: hidden;
  display: flex; }

.inspector-script-window-row-edit {
  width: 25px;
  height: 100%;
  background-color: #303030;
  color: white;
  border-radius: 3px; }

.inspector-script-window-row-delete {
  width: 25px;
  height: 100%;
  background-color: #303030;
  color: white;
  border-radius: 3px; }

.inspector-script-window-row-color-bar {
  height: 100%;
  width: 5px;
  margin-right: 5px; }

.inspector-script-window-row-color-bar.blue-bar {
  background-color: #05B8FF; }

.inspector-script-window-row-color-bar.green-bar {
  background-color: #29F1A3; }

.inspector-script-window-row-color-bar.yellow-bar {
  background-color: #FFE243; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.inspector {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.inspector-body {
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.inspector-body:hover {
  overflow-y: auto; }

.input-divider {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Nunito Sans';
  display: flex;
  align-items: center;
  justify-content: center; }

.inspector-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: flex;
  flex-direction: column;
  align-items: center; }

.select-inspector {
  height: 20px; }

.inspector-action-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px; }

.inspector-script-window-row-container {
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 3px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px; }

.inspector-script-window-container {
  width: 100%;
  height: 100px;
  background-color: #4A4A4A;
  border-radius: 3px; }

.inspector-script-window-container {
  height: 100%;
  padding-bottom: 5px; }

.inspector-script-window-header {
  width: 100%;
  height: 25px;
  background-color: #3B3B3B;
  color: #ffffff;
  text-align: center;
  vertical-align: center;
  border-radius: 3px 3px 0px 0px; }

.inspector-add-script-button {
  height: 25px;
  width: 100%; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.menu-bar {
  height: 100%;
  width: 100%;
  background-color: #303030;
  overflow: visible;
  border: 4px solid #191919;
  display: flex;
  box-sizing: border-box;
  align-items: center; }

.menu-bar-project-name {
  transition: .4s;
  font-size: 18px;
  font-family: 'Nunito Sans';
  color: #FFFFFF;
  cursor: pointer;
  margin: 0 auto; }

.menu-bar-info-container {
  height: 100%;
  display: flex;
  flex-direction: row; }

.menu-bar-project-name-mobile {
  transition: .4s;
  font-size: 18px;
  font-family: 'Nunito Sans';
  color: #FFFFFF;
  text-align: center;
  margin: 0 auto; }

.menu-bar-actions-container {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: 'Nunito Sans';
  float: right;
  height: 100%; }

.project-settings-preview {
  height: 70%;
  padding-left: 8px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  cursor: pointer; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.menu-bar-button {
  width: auto;
  height: 80%;
  float: left;
  padding-left: 4px;
  margin-right: 8px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.menu-bar-icon-button {
  height: 100%;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center; }

.menu-bar-icon-button:last-child {
  margin-right: 6px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.menu-bar-support-button {
  height: 100%;
  padding: 2px;
  float: left; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

body {
  overflow: hidden;
  text-rendering: optimizeLegibility; }

.toolbox-container {
  height: 40px;
  transition-timing-function: ease-in-out;
  transition: width .5s, height .5s; }

.toolbox-container-medium {
  height: 80px; }

.toolbox-container-small {
  height: 80px; }

.editor-canvas-timeline-panel {
  height: calc(100% - 40px);
  transition-timing-function: ease-in-out;
  transition: width .5s, height .5s; }

.editor-canvas-timeline-panel-medium {
  height: calc(100% - 80px); }

.editor-canvas-timeline-panel-small {
  height: calc(100% - 80px); }

#editor {
  width: 100%;
  height: 100%;
  background-color: red; }

#menu-bar-container {
  width: 100%;
  height: 40px;
  background-color: green; }

#editor-body {
  width: 100%;
  height: calc(100% - 40px);
  background-color: blue;
  overflow: hidden; }

#flexible-container {
  height: calc(100% - 40px);
  width: 100%;
  background-color: #AAA;
  display: inline-block;
  position: absolute;
  top: 40px; }

.center-container {
  background-color: #ffaabb; }

.right-container {
  background-color: #aabbcc; }

.right-reflex-container {
  height: 100%;
  background-color: #aabbcc; }

.Toastify__toast {
  font-family: 'Nunito Sans';
  font-weight: 800;
  font-size: 24px;
  border-radius: 3px; }

.Toastify__toast button {
  font-family: 'Nunito Sans';
  font-weight: 800;
  font-size: 24px; }

.info-toast-background {
  background-color: #383434; }

.info-toast-background button {
  color: #FFFFFF; }

.info-toast-body {
  color: #FFFFFF; }

.error-toast-background {
  background-color: #AC1706; }

.error-toast-background button {
  color: #FFFFFF; }

.error-toast-body {
  color: #FFFFFF; }

.warning-toast-background {
  background-color: #FFE243; }

.warning-toast-background button {
  color: #000000; }

.warning-toast-body {
  color: #000000; }

.success-toast-background {
  background-color: #29F1A3; }

.success-toast-background button {
  color: #000000; }

.success-toast-body {
  color: #000000; }

.mobile-editor-body {
  border-right-width: calc(3px * 2);
  border-right-style: solid;
  border-right-color: #191919 !important; }

/* Basic re-flex panel/splitter styling */
.reflex-container {
  justify-content: flex-start;
  /* align items in Main Axis */
  align-items: stretch;
  /* align items in Cross Axis */
  align-content: stretch;
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  position: relative;
  height: 100%;
  width: 100%; }

.reflex-container.horizontal {
  flex-direction: column; }

.reflex-container.vertical {
  flex-direction: row; }

.reflex-container > .reflex-element {
  position: relative;
  overflow: auto;
  height: 100%;
  width: 100%; }

.reflex-container.reflex-resizing > .reflex-element {
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.reflex-container > .reflex-element > .reflex-size-aware {
  height: 100%;
  width: 100%; }

.reflex-container > .reflex-splitter {
  background-color: #191919;
  z-index: 0; }

.reflex-container > .reflex-splitter.active,
.reflex-container > .reflex-splitter:hover {
  background-color: white;
  transition: all 1s ease; }

.horizontal > .reflex-splitter {
  border-bottom: 3px solid #191919 !important;
  border-top: 3px solid #191919 !important;
  cursor: row-resize;
  width: 100%;
  height: 3px; }

.reflex-element.horizontal .reflex-handle {
  cursor: row-resize;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.reflex-container.horizontal > .reflex-splitter:hover,
.reflex-container.horizontal > .reflex-splitter.active {
  border-bottom: 3px solid white !important;
  border-top: 3px solid white !important; }

.reflex-container.vertical > .reflex-splitter {
  border-right: 3px solid #191919 !important;
  border-left: 3px solid #191919 !important;
  cursor: col-resize;
  height: 100%;
  width: 3px; }

.reflex-element.vertical .reflex-handle {
  cursor: col-resize;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.reflex-container.vertical > .reflex-splitter:hover,
.reflex-container.vertical > .reflex-splitter.active {
  border-right: 3px solid white !important;
  border-left: 3px solid white !important; }

.reflex-container > .reflex-splitter.reflex-thin {
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  opacity: 0.2;
  z-index: 0; }

.reflex-container > .reflex-splitter.reflex-thin.active
.reflex-container > .reflex-splitter.reflex-thin:hover {
  transition: all 1.5s ease;
  opacity: 0.5; }

.reflex-container.horizontal > .reflex-splitter.reflex-thin {
  border-bottom: 3px solid #191919 !important;
  border-top: 3px solid #191919 !important;
  height: 3px;
  cursor: row-resize;
  margin: -3px 0;
  width: 100%; }

.reflex-container.horizontal > .reflex-splitter.reflex-thin.active,
.reflex-container.horizontal > .reflex-splitter.reflex-thin:hover {
  border-bottom: 3px solid white !important;
  border-top: 3px solid white !important; }

.reflex-container.vertical > .reflex-splitter.reflex-thin {
  border-right: 3px solid #191919 !important;
  border-left: 3px solid #191919 !important;
  width: 3px !important;
  cursor: col-resize;
  margin: -3px;
  height: 100%; }

.reflex-container.vertical > .reflex-splitter.reflex-thin.active,
.reflex-container.vertical > .reflex-splitter.reflex-thin:hover {
  border-right: 3px solid white !important;
  border-left: 3px solid white !important; }

.mobile-reflex-splitter {
  height: 15px !important;
  border: 3px solid black !important; }

.timeline {
  background-color: #E6460E; }

#animation-timeline-container {
  height: 100%; }

#animation-timeline {
  width: 100%;
  height: 100%;
  border-left: 4px solid #191919;
  border-bottom: 4px solid #191919; }

.animation-timeline-add-keyframe-button {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 27px;
  left: 170px; }


/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.mobile-tabbed-interface {
  background-color: transparent !important;
  width: 100%;
  height: 100%; }

.mobile-tabbed-interface-main-tab-container {
  background-color: #666666 !important;
  min-height: 42px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between; }

.mobile-tabbed-interface-main-tab {
  background-color: #191919;
  min-height: 34px;
  border-radius: 5px 5px 0 0;
  height: 100%;
  width: 22%;
  border: none;
  cursor: pointer; }

.mobile-tabbed-interface-main-tab.selected {
  min-height: 42px;
  transition: .4s;
  border-top: 4px solid #1EE29A; }

.mobile-tabbed-interface-main-tab.selected.mobile-timeline-tab {
  border-top: 4px solid #FA8A87; }

.mobile-tabbed-interface-main-tab.selected.mobile-inspector-tab {
  border-top: 4px solid #4FF7DE; }

.mobile-tabbed-interface-main-tab.selected.mobile-code-tab {
  border-top: 4px solid #FA73FA; }

.mobile-tabbed-interface-main-tab.selected.mobile-asset-tab {
  border-top: 4px solid #BAFA98; }

.mobile-tabbed-interface-icon {
  margin: auto; }

.mobile-tabbed-interface-main-tab:first-child {
  margin-left: 0px; }

.mobile-tabbed-interface-body {
  background-color: #191919;
  width: 100%;
  height: calc(100% - 42px); }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.mobile-inspector {
  background-color: #191919;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden;
  margin: 0; }

.inspector-body {
  height: calc(100% - 36px);
  width: 100%; }

.inspector-body:hover {
  overflow-y: auto; }

.input-divider {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Nunito Sans';
  display: flex;
  align-items: center;
  justify-content: center; }

.mobile-inspector-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px; }

.select-inspector {
  height: 20px; }

.inspector-action-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px; }

.mobile-inspector-item.mobile-inspector-name {
  width: 50%;
  border: none;
  padding: 0px;
  margin-left: auto;
  height: 80%; }

.mobile-inspector-item.mobile-inspector-item-style {
  display: flex;
  flex-direction: row; }

.mobile-inspector-col-left {
  width: 40%; }

.mobile-inspector-col-right {
  width: 60%; }

#mobile-inspector-selection-stroke-color {
  width: 40px;
  height: 40px;
  border-radius: 10px; }

#mobile-inspector-selection-fill-color {
  width: 40px;
  height: 40px;
  border-radius: 10px; }

.mobile-inspector-unknown-selection {
  color: white;
  font-size: 32px;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 12px; }

.mobile-inspector-title {
  font-size: 20px;
  color: white;
  padding-left: 8px;
  display: flex;
  align-items: center;
  margin-right: auto; }

.mobile-inspector-title-prefix {
  color: #666666;
  margin-right: 8px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.mobile-inspector {
  background-color: #191919;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden;
  margin: 0; }

.inspector-body {
  height: calc(100% - 36px);
  width: 100%; }

.inspector-body:hover {
  overflow-y: auto; }

.input-divider {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Nunito Sans';
  display: flex;
  align-items: center;
  justify-content: center; }

.mobile-inspector-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px; }

.select-inspector {
  height: 20px; }

.inspector-action-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px; }

.mobile-inspector-item.mobile-inspector-name {
  width: 50%;
  border: none;
  padding: 0px;
  margin-left: auto;
  height: 80%; }

.mobile-inspector-item.mobile-inspector-item-style {
  display: flex;
  flex-direction: row; }

.mobile-inspector-col-left {
  width: 40%; }

.mobile-inspector-col-right {
  width: 60%; }

#mobile-inspector-selection-stroke-color {
  width: 40px;
  height: 40px;
  border-radius: 10px; }

#mobile-inspector-selection-fill-color {
  width: 40px;
  height: 40px;
  border-radius: 10px; }

.mobile-inspector-unknown-selection {
  color: white;
  font-size: 32px;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 12px; }

.mobile-inspector-title {
  font-size: 20px;
  color: white;
  padding-left: 8px;
  display: flex;
  align-items: center;
  margin-right: auto; }

.mobile-inspector-title-prefix {
  color: #666666;
  margin-right: 8px; }

.mobile-inspector-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 26px;
  max-height: 70px;
  margin-bottom: 10px;
  align-items: center; }

.mobile-inspector-row:last-child {
  margin-bottom: 0px; }

.mobile-inspector-row-identifier {
  display: flex;
  flex-direction: column;
  min-width: 28%;
  max-width: 60%;
  height: 100%;
  color: white;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  text-align: right;
  padding-right: 1.5%;
  padding-left: 1.5%;
  margin-top: 3px;
  margin-bottom: 0; }

.mobile-inspector-row-identifier.mobile-inspector-row-identifier-color {
  min-width: 40%;
  margin-right: 10px; }

.inspector-row-identifier:first-child {
  padding-left: 0; }

.mobile-inspector-row-icon {
  margin-right: 5px;
  width: 20px;
  height: auto; }

.mobile-inspector-small-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 38%;
  min-width: 30px;
  height: 90%;
  padding-left: 1.5%;
  margin-right: 5%; }

.mobile-inspector-small-input-container:last-child {
  padding-right: 0; }

.mobile-inspector-medium-input-container {
  width: calc(100% - 28% - 38%);
  padding-right: 0;
  padding-left: 1.5%; }

.mobile-inspector-large-input-container {
  width: calc(100% - 28%);
  padding-right: 0;
  padding-left: 1.5%; }

.inspector-input-element {
  display: inline-block;
  height: 100%;
  width: 100%; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.mobile-inspector-tabbed-interface {
  background-color: #191919 !important;
  width: 100%;
  height: 100%; }

.mobile-inspector-tabbed-interface-main-tab-container {
  padding-top: 5px;
  background-color: #191919 !important;
  min-height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start; }

.mobile-inspector-tabbed-interface-main-tab {
  background-color: #262626;
  min-height: 40px;
  border-radius: 5px 5px 0 0;
  height: 100%;
  width: 15%;
  margin-right: 5px;
  border: none;
  border-bottom: 3px solid #484747;
  cursor: pointer; }

.mobile-inspector-tabbed-interface-main-tab.selected {
  background-color: #303030;
  transition: .4s;
  border-bottom: 3px solid #1EE29A; }

.mobile-inspector-tabbed-interface-icon {
  margin: auto; }

.mobile-inspector-tabbed-interface-main-tab:first-child {
  margin-left: 0px; }

.mobile-inspector-tabbed-interface-body {
  background-color: #303030;
  width: 100%;
  height: calc(100% - 40px); }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.asset-buttons-container {
  margin-bottom: 4px;
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1; }

.asset-button {
  height: 28px;
  padding: none;
  margin-right: 4px; }

.add {
  width: calc(100% - 25px - 2 * 4px); }

.audio-player {
  width: calc(100% - 25px - 2 * 4px);
  height: 28px; }

.delete {
  padding: none;
  width: 25px; }

.select {
  color: unset;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  padding-left: 0; }

.asset-item {
  border: none;
  transition: .2s;
  width: calc(100% - 2 * 4px);
  line-height: 28px;
  background-color: #3B3B3B;
  color: #FFFFFF;
  margin-top: 4px;
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 2px;
  overflow: hidden;
  text-align: left;
  vertical-align: center; }

.asset-item:hover {
  background-color: #303030;
  color: #FFFFFF;
  cursor: -webkit-grab;
  cursor: grab; }

.asset-icon {
  display: inline-flex;
  width: calc(28px * .8);
  height: calc(28px * .8);
  margin-right: 5px; }

.asset-name-text {
  margin-left: 4px;
  white-space: nowrap;
  overflow: scroll;
  height: 100%;
  overflow: hidden; }

.asset-item.asset-selected {
  border: 2px solid #1EE29A;
  margin: calc(4px - 2px);
  transition: .2s;
  background-color: #303030;
  color: #FFFFFF; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.mobile-asset-library {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #303030;
  border-right: 4px solid #191919;
  border-bottom: 4px solid #191919;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row; }

.mobile-asset-library-left-container {
  order: 1;
  margin-top: 6px;
  width: 50%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }

.mobile-asset-library-filter {
  top: 0;
  width: 100%;
  height: 40px;
  max-height: 40px;
  background-color: #3B3B3B;
  display: flex;
  align-items: center;
  margin-bottom: 10px; }

.mobile-asset-library-filter-icon {
  height: calc(40px * .5);
  width: calc(40px * .5);
  margin-left: 4px;
  margin-right: 4px; }

#mobile-asset-library-filter-input {
  background: transparent;
  border: none;
  color: #CFCFCF; }

#mobile-asset-library-filter-input::-webkit-input-placeholder {
  color: #CFCFCF; }

#mobile-asset-library-filter-input::-ms-input-placeholder {
  color: #CFCFCF; }

#mobile-asset-library-filter-input::placeholder {
  color: #CFCFCF; }

.mobile-btn-asset-builtin {
  width: 100%;
  height: 35px;
  margin-bottom: 5px; }

.mobile-btn-asset-upload {
  width: 100%;
  height: 35px; }

.mobile-asset-library-right-container {
  order: 2;
  background-color: #4A4A4A;
  margin-top: 6px;
  margin-bottom: 10px;
  border-radius: 4px;
  overflow: hidden;
  display: block;
  width: 50%; }

.mobile-asset-library-asset-container {
  height: 100%;
  overflow: hidden; }

.mobile-asset-library-asset-container:hover {
  overflow-y: auto; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.canvas-transforms-widget {
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 40px;
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  background-color: #191919;
  border-radius: 4px; }

.canvas-transforms-widget.mobile {
  margin-right: 0;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px; }

#canvas-zoom-options-container {
  display: flex;
  flex-direction: row;
  align-items: center; }

#zoom-tool-container {
  width: 40px;
  height: 40px; }

.canvas-transform-wick-button {
  border-radius: 0px; }

.zoom-tool {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.canvas-transform-item {
  padding: 2px; }

.canvas-transform-button {
  width: 40px;
  height: 40px;
  padding: 3px;
  padding-left: 1.5px;
  padding-right: 1.5px; }

.thin-transform-button {
  width: calc(40px * .6); }

.zoom-out-button {
  padding-left: 0px; }

.zoom-in-button {
  padding-right: 0px; }

.canvas-transform-icon {
  width: 90%; }

.play-button {
  width: 100%;
  height: 100%;
  padding: 3px; }

.play-button-container {
  width: calc(40px * 1.5);
  height: calc(40px * 1.5);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #191919;
  border-radius: 30px; }

.transforms-container {
  display: flex;
  flex-direction: row;
  align-items: center; }

.transforms-container:first-child {
  padding-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.canvas-transforms-widget {
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 40px;
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  background-color: #191919;
  border-radius: 4px; }

.canvas-transforms-widget.mobile {
  margin-right: 0;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px; }

#canvas-zoom-options-container {
  display: flex;
  flex-direction: row;
  align-items: center; }

#zoom-tool-container {
  width: 40px;
  height: 40px; }

.canvas-transform-wick-button {
  border-radius: 0px; }

.zoom-tool {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.canvas-transform-item {
  padding: 2px; }

.canvas-transform-button {
  width: 40px;
  height: 40px;
  padding: 3px;
  padding-left: 1.5px;
  padding-right: 1.5px; }

.thin-transform-button {
  width: calc(40px * .6); }

.zoom-out-button {
  padding-left: 0px; }

.zoom-in-button {
  padding-right: 0px; }

.canvas-transform-icon {
  width: 90%; }

.play-button {
  width: 100%;
  height: 100%;
  padding: 3px; }

.play-button-container {
  width: calc(40px * 1.5);
  height: calc(40px * 1.5);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #191919;
  border-radius: 30px; }

.transforms-container {
  display: flex;
  flex-direction: row;
  align-items: center; }

.transforms-container:first-child {
  padding-left: 2px; }

.delete-copy-paste-widget {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 40px;
  margin-right: 15px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  background-color: #191919;
  border-radius: 4px; }

.delete-copy-paste-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 2px; }

.disabled {
  opacity: 0.25; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.play-icon {
  cursor: pointer;
  height: 100%;
  width: 100%; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.tool-box {
  width: 100%;
  height: 100%;
  background-color: #303030;
  padding-left: 2px;
  padding-right: 4px;
  border: 4px solid #191919;
  border-top: none;
  border-right: none;
  display: flex;
  overflow-x: hidden; }

.tool-box-large {
  flex-direction: row;
  align-items: center;
  overflow: hidden; }

.tool-box.tool-box-medium {
  flex-direction: column;
  overflow: hidden;
  height: 80px; }

.medium-toolbox-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 3px solid #191919;
  width: 100%;
  height: 50%; }

.medium-toolbox-row:last-child {
  border-bottom: none; }

.tool-box-container {
  height: 100%;
  width: 100%;
  overflow: hidden; }

.tool-collection-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%; }

.toolbox-item {
  width: 30px;
  max-width: 30px;
  height: 30px;
  margin-left: 3px;
  margin-right: 3px; }

.toolbox-item.mobile {
  margin-right: 0; }

.tool-selector-item {
  margin-top: 3px;
  margin-bottom: 3px; }

.tool-selector-item:last-child {
  margin-bottom: 0; }

.toolbox-actions-center {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center; }

.color-container {
  cursor: pointer;
  width: calc(30px * .85);
  height: calc(30px * .85);
  float: left;
  min-width: calc(30px * .85);
  align-items: center;
  display: flex;
  overflow: hidden; }

#tool-box-stroke-color-button {
  border-radius: 20px; }

#tool-box-fill-color-button {
  border-radius: 20px; }

#stroke-color-picker-container {
  box-sizing: border-box; }

.toolbox-actions-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: right; }

.toolbox-actions-right {
  display: flex;
  justify-content: center;
  align-items: center; }

.toolbox-actions-right-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
  height: 100%; }

.bump-up-no-dropdown {
  margin-bottom: 2.5px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.toolbox-break {
  height: 70%;
  width: 3px;
  min-width: 3px;
  background-color: #191919;
  margin-left: 8px;
  margin-right: 8px; }

.toolbox-break-vertical {
  width: 25px;
  height: 3px;
  min-height: 3px;
  background-color: #191919;
  margin-top: 8px;
  margin-bottom: 8px; }

.toolbox-break.mobile {
  margin-left: 4px;
  margin-right: 4px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.tool-box {
  width: 100%;
  height: 100%;
  background-color: #303030;
  padding-left: 2px;
  padding-right: 4px;
  border: 4px solid #191919;
  border-top: none;
  border-right: none;
  display: flex;
  overflow-x: hidden; }

.tool-box-large {
  flex-direction: row;
  align-items: center;
  overflow: hidden; }

.tool-box.tool-box-medium {
  flex-direction: column;
  overflow: hidden;
  height: 80px; }

.medium-toolbox-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 3px solid #191919;
  width: 100%;
  height: 50%; }

.medium-toolbox-row:last-child {
  border-bottom: none; }

.tool-box-container {
  height: 100%;
  width: 100%;
  overflow: hidden; }

.tool-collection-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%; }

.toolbox-item {
  width: 30px;
  max-width: 30px;
  height: 30px;
  margin-left: 3px;
  margin-right: 3px; }

.toolbox-item.mobile {
  margin-right: 0; }

.tool-selector-item {
  margin-top: 3px;
  margin-bottom: 3px; }

.tool-selector-item:last-child {
  margin-bottom: 0; }

.toolbox-actions-center {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center; }

.color-container {
  cursor: pointer;
  width: calc(30px * .85);
  height: calc(30px * .85);
  float: left;
  min-width: calc(30px * .85);
  align-items: center;
  display: flex;
  overflow: hidden; }

#tool-box-stroke-color-button {
  border-radius: 20px; }

#tool-box-fill-color-button {
  border-radius: 20px; }

#stroke-color-picker-container {
  box-sizing: border-box; }

.toolbox-actions-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: right; }

.toolbox-actions-right {
  display: flex;
  justify-content: center;
  align-items: center; }

.toolbox-actions-right-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
  height: 100%; }

.bump-up-no-dropdown {
  margin-bottom: 2.5px; }

.tool-button-container {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.tool-button-select-container {
  width: 30px;
  height: 30px; }

.tool-button-icon {
  height: 80%; }

#settings-panel-container {
    --settings-input-size: 40px;
    height: 100%;

}

.settings-input-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.setting-input-container {
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}
  
.setting-input-container.mobile {
    margin: 0;
}

.settings-numeric-slider {
    display: flex;
    height: 80%;
    margin-right: 8px;
}

.settings-numeric-slider-container {
    background-color: var(--wick-primary);
    border-radius: var(--border-radius-secondary);
}

.settings-numeric-slider-icon {
    margin-right: 8px;
    padding: 2px;
    height: 24px;
    width: 24px; 
    margin-top: auto;
    margin-bottom: auto;
}

.settings-numeric-input {
    display: flex;
    flex: 1 1;
    width: 40px;
    text-align: center;
}

.settings-numeric-input.mobile {
    width: 34px !important;
    flex: none;
}
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.tool-box {
  width: 100%;
  height: 100%;
  background-color: #303030;
  padding-left: 2px;
  padding-right: 4px;
  border: 4px solid #191919;
  border-top: none;
  border-right: none;
  display: flex;
  overflow-x: hidden; }

.tool-box-large {
  flex-direction: row;
  align-items: center;
  overflow: hidden; }

.tool-box.tool-box-medium {
  flex-direction: column;
  overflow: hidden;
  height: 80px; }

.medium-toolbox-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 3px solid #191919;
  width: 100%;
  height: 50%; }

.medium-toolbox-row:last-child {
  border-bottom: none; }

.tool-box-container {
  height: 100%;
  width: 100%;
  overflow: hidden; }

.tool-collection-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%; }

.toolbox-item {
  width: 30px;
  max-width: 30px;
  height: 30px;
  margin-left: 3px;
  margin-right: 3px; }

.toolbox-item.mobile {
  margin-right: 0; }

.tool-selector-item {
  margin-top: 3px;
  margin-bottom: 3px; }

.tool-selector-item:last-child {
  margin-bottom: 0; }

.toolbox-actions-center {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center; }

.color-container {
  cursor: pointer;
  width: calc(30px * .85);
  height: calc(30px * .85);
  float: left;
  min-width: calc(30px * .85);
  align-items: center;
  display: flex;
  overflow: hidden; }

#tool-box-stroke-color-button {
  border-radius: 20px; }

#tool-box-fill-color-button {
  border-radius: 20px; }

#stroke-color-picker-container {
  box-sizing: border-box; }

.toolbox-actions-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: right; }

.toolbox-actions-right {
  display: flex;
  justify-content: center;
  align-items: center; }

.toolbox-actions-right-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
  height: 100%; }

.bump-up-no-dropdown {
  margin-bottom: 2.5px; }

#settings-panel-container {
  display: flex;
  justify-content: center;
  background-color: transparent;
  align-items: center; }

.settings-input-container {
  display: flex;
  justify-content: center;
  align-items: center; }

.brush-modes-widget {
  height: 35px;
  display: flex;
  align-items: center;
  margin: 1px; }

.settings-text-label {
  color: white;
  font-size: 12px;
  bottom: 0px;
  text-align: center; }

.settings-input-icon {
  width: 20px;
  height: 20px;
  margin-right: 4px; }

.settings-numeric-input-container {
  display: flex;
  align-items: center;
  width: calc(2 * 40px); }

.settings-numeric-wick-input-container {
  width: calc(40px);
  margin-right: 4px; }

.settings-checkbox-input {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center; }

.toolbox-input-icon {
  width: 100%; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.popup-menu-popover {
  background-color: #303030;
  border: 2px solid #191919;
  max-width: 375px; }

.actions-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.tool-box {
  width: 100%;
  height: 100%;
  background-color: #303030;
  padding-left: 2px;
  padding-right: 4px;
  border: 4px solid #191919;
  border-top: none;
  border-right: none;
  display: flex;
  overflow-x: hidden; }

.tool-box-large {
  flex-direction: row;
  align-items: center;
  overflow: hidden; }

.tool-box.tool-box-medium {
  flex-direction: column;
  overflow: hidden;
  height: 80px; }

.medium-toolbox-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 3px solid #191919;
  width: 100%;
  height: 50%; }

.medium-toolbox-row:last-child {
  border-bottom: none; }

.tool-box-container {
  height: 100%;
  width: 100%;
  overflow: hidden; }

.tool-collection-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%; }

.toolbox-item {
  width: 30px;
  max-width: 30px;
  height: 30px;
  margin-left: 3px;
  margin-right: 3px; }

.toolbox-item.mobile {
  margin-right: 0; }

.tool-selector-item {
  margin-top: 3px;
  margin-bottom: 3px; }

.tool-selector-item:last-child {
  margin-bottom: 0; }

.toolbox-actions-center {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center; }

.color-container {
  cursor: pointer;
  width: calc(30px * .85);
  height: calc(30px * .85);
  float: left;
  min-width: calc(30px * .85);
  align-items: center;
  display: flex;
  overflow: hidden; }

#tool-box-stroke-color-button {
  border-radius: 20px; }

#tool-box-fill-color-button {
  border-radius: 20px; }

#stroke-color-picker-container {
  box-sizing: border-box; }

.toolbox-actions-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: right; }

.toolbox-actions-right {
  display: flex;
  justify-content: center;
  align-items: center; }

.toolbox-actions-right-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
  height: 100%; }

.bump-up-no-dropdown {
  margin-bottom: 2.5px; }

#settings-panel-container {
  display: flex;
  justify-content: center;
  background-color: transparent;
  align-items: center; }

.settings-input-container {
  display: flex;
  justify-content: center;
  align-items: center; }

.brush-modes-widget {
  height: 35px;
  display: flex;
  align-items: center;
  margin: 1px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.canvas-actions-widget {
  height: 35px;
  display: flex;
  align-items: center;
  margin: 1px; }

.canvas-actions-widget.vertical {
  height: unset; }

.actions-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center; }

.actions-container.vertical {
  flex-direction: column; }

.canvas-action-button {
  width: 35px;
  height: 35px;
  padding: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.asset-buttons-container {
  margin-bottom: 4px;
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1; }

.asset-button {
  height: 28px;
  padding: none;
  margin-right: 4px; }

.add {
  width: calc(100% - 25px - 2 * 4px); }

.audio-player {
  width: calc(100% - 25px - 2 * 4px);
  height: 28px; }

.delete {
  padding: none;
  width: 25px; }

.select {
  color: unset;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  padding-left: 0; }

.asset-item {
  border: none;
  transition: .2s;
  width: calc(100% - 2 * 4px);
  line-height: 28px;
  background-color: #3B3B3B;
  color: #FFFFFF;
  margin-top: 4px;
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 2px;
  overflow: hidden;
  text-align: left;
  vertical-align: center; }

.asset-item:hover {
  background-color: #303030;
  color: #FFFFFF;
  cursor: -webkit-grab;
  cursor: grab; }

.asset-icon {
  display: inline-flex;
  width: calc(28px * .8);
  height: calc(28px * .8);
  margin-right: 5px; }

.asset-name-text {
  margin-left: 4px;
  white-space: nowrap;
  overflow: scroll;
  height: 100%;
  overflow: hidden; }

.asset-item.asset-selected {
  border: 2px solid #1EE29A;
  margin: calc(4px - 2px);
  transition: .2s;
  background-color: #303030;
  color: #FFFFFF; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.asset-library {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #303030;
  border-right: 4px solid #191919;
  border-bottom: 4px solid #191919;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column; }

.asset-library-title-container {
  display: flex;
  align-items: center;
  margin-top: 6px; }

.asset-library-title-text {
  color: #FFFFFF;
  font-size: 20px;
  display: inline-flex;
  align-items: center;
  width: 200px; }

.btn-asset-builtin {
  width: 25px;
  height: 25px; }

.btn-asset-upload {
  width: 25px;
  height: 25px;
  margin-right: 5px; }

.asset-library-body {
  height: 100%;
  background-color: #4A4A4A;
  margin-top: 6px;
  margin-bottom: 10px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column; }

.asset-library-filter {
  top: 0;
  width: 100%;
  height: 40px;
  max-height: 40px;
  background-color: #3B3B3B;
  display: flex;
  align-items: center; }

.asset-library-filter-icon {
  height: calc(40px * .5);
  width: calc(40px * .5);
  margin-left: 4px;
  margin-right: 4px; }

.asset-library-asset-container {
  height: 100%;
  overflow: hidden; }

.asset-library-asset-container:hover {
  overflow-y: auto; }

#asset-library-filter-input {
  background: transparent;
  border: none;
  color: #CFCFCF; }

#asset-library-filter-input::-webkit-input-placeholder {
  color: #CFCFCF; }

#asset-library-filter-input::-ms-input-placeholder {
  color: #CFCFCF; }

#asset-library-filter-input::placeholder {
  color: #CFCFCF; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.outliner {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.popout-outliner {
  border-right: 0; }

.outliner-body {
  color: white;
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.outliner-body:hover {
  overflow-y: auto; }

.outliner-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: flex;
  flex-direction: column; }

.outliner-script-icon {
  vertical-align: top;
  margin-top: calc(4px/2);
  height: 16px; }

.outliner-sound-icon {
  vertical-align: top;
  margin-top: 0;
  height: 20px;
  margin-right: 4px; }

.outliner-object {
  align-content: flex-start;
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  background-color: #3B3B3B;
  border-top: 2px solid #303030;
  border-bottom: 2px solid #303030; }

.outliner-object:hover {
  background-color: #4A4A4A;
  cursor: pointer; }

.outliner-object-selector {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 100%;
  border-style: none;
  background-color: transparent; }

.object-selected {
  border: 2px solid #1EE29A; }

.object-dragging {
  opacity: 0.5; }

.outliner-object-container {
  position: relative;
  width: 100%;
  height: 100%; }

.highlighted {
  background-color: #4A4A4A; }

.outliner-name {
  position: relative;
  z-index: -1;
  color: white;
  font-size: 16px;
  margin-left: calc(4px * 2); }

.row-icon {
  position: relative;
  z-index: -1;
  width: 14px;
  margin-left: 4px;
  margin-bottom: 3px; }

@-webkit-keyframes expand {
  0% {
    transform: scaleY(0); }
  100% {
    transform: scaleY(1); } }

@keyframes expand {
  0% {
    transform: scaleY(0); }
  100% {
    transform: scaleY(1); } }

.indentation {
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  padding-left: 25px;
  transform-origin: top;
  -webkit-animation-name: expand;
          animation-name: expand;
  -webkit-animation-duration: 75ms;
          animation-duration: 75ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.outliner-buttons-container {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 0;
  float: right;
  margin-top: calc(4px / 2); }

.hover-top {
  border-top: calc(2px * 2) #00ADEF solid; }

.hover-middle {
  background-color: #00ADEF; }

.hover-bottom {
  border-bottom: calc(2px * 2) #00ADEF solid; }

.widget {
  vertical-align: top;
  max-width: 20px;
  max-height: 20px !important;
  width: 20px;
  height: 20px !important;
  border: none;
  background: none;
  padding: 0;
  margin-right: 4px;
  transition: background-color 150ms ease-in-out;
  border-radius: 3px; }

.widget-off {
  max-height: 20px;
  vertical-align: top;
  opacity: 0.25; }

.widget-on {
  max-height: 20px;
  vertical-align: top;
  opacity: 1; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.outliner {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.popout-outliner {
  border-right: 0; }

.outliner-body {
  color: white;
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.outliner-body:hover {
  overflow-y: auto; }

.outliner-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: flex;
  flex-direction: column; }

.outliner-script-icon {
  vertical-align: top;
  margin-top: calc(4px/2);
  height: 16px; }

.outliner-sound-icon {
  vertical-align: top;
  margin-top: 0;
  height: 20px;
  margin-right: 4px; }

.outliner-object {
  align-content: flex-start;
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  background-color: #3B3B3B;
  border-top: 2px solid #303030;
  border-bottom: 2px solid #303030; }

.outliner-object:hover {
  background-color: #4A4A4A;
  cursor: pointer; }

.outliner-object-selector {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 100%;
  border-style: none;
  background-color: transparent; }

.object-selected {
  border: 2px solid #1EE29A; }

.object-dragging {
  opacity: 0.5; }

.outliner-object-container {
  position: relative;
  width: 100%;
  height: 100%; }

.highlighted {
  background-color: #4A4A4A; }

.outliner-name {
  position: relative;
  z-index: -1;
  color: white;
  font-size: 16px;
  margin-left: calc(4px * 2); }

.row-icon {
  position: relative;
  z-index: -1;
  width: 14px;
  margin-left: 4px;
  margin-bottom: 3px; }

@-webkit-keyframes expand {
  0% {
    transform: scaleY(0); }
  100% {
    transform: scaleY(1); } }

@keyframes expand {
  0% {
    transform: scaleY(0); }
  100% {
    transform: scaleY(1); } }

.indentation {
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  padding-left: 25px;
  transform-origin: top;
  -webkit-animation-name: expand;
          animation-name: expand;
  -webkit-animation-duration: 75ms;
          animation-duration: 75ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.outliner-buttons-container {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 0;
  float: right;
  margin-top: calc(4px / 2); }

.hover-top {
  border-top: calc(2px * 2) #00ADEF solid; }

.hover-middle {
  background-color: #00ADEF; }

.hover-bottom {
  border-bottom: calc(2px * 2) #00ADEF solid; }

.widget {
  vertical-align: top;
  max-width: 20px;
  max-height: 20px !important;
  width: 20px;
  height: 20px !important;
  border: none;
  background: none;
  padding: 0;
  margin-right: 4px;
  transition: background-color 150ms ease-in-out;
  border-radius: 3px; }

.widget-off {
  max-height: 20px;
  vertical-align: top;
  opacity: 0.25; }

.widget-on {
  max-height: 20px;
  vertical-align: top;
  opacity: 1; }

@-webkit-keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(90deg); } }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(90deg); } }

.outliner-dropdown-icon {
  position: relative;
  z-index: 0;
  margin-left: 4px;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  transition: background-color 150ms ease-in-out; }

.outliner-dropdown-icon:hover {
  background-color: rgba(255, 255, 255, 0.2); }

.expanded {
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-duration: 75ms;
          animation-duration: 75ms;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.empty {
  padding: calc(15px * .3);
  z-index: -1; }

.empty:hover {
  background: none; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.outliner {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.popout-outliner {
  border-right: 0; }

.outliner-body {
  color: white;
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.outliner-body:hover {
  overflow-y: auto; }

.outliner-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: flex;
  flex-direction: column; }

.outliner-script-icon {
  vertical-align: top;
  margin-top: calc(4px/2);
  height: 16px; }

.outliner-sound-icon {
  vertical-align: top;
  margin-top: 0;
  height: 20px;
  margin-right: 4px; }

.outliner-object {
  align-content: flex-start;
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  background-color: #3B3B3B;
  border-top: 2px solid #303030;
  border-bottom: 2px solid #303030; }

.outliner-object:hover {
  background-color: #4A4A4A;
  cursor: pointer; }

.outliner-object-selector {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 100%;
  border-style: none;
  background-color: transparent; }

.object-selected {
  border: 2px solid #1EE29A; }

.object-dragging {
  opacity: 0.5; }

.outliner-object-container {
  position: relative;
  width: 100%;
  height: 100%; }

.highlighted {
  background-color: #4A4A4A; }

.outliner-name {
  position: relative;
  z-index: -1;
  color: white;
  font-size: 16px;
  margin-left: calc(4px * 2); }

.row-icon {
  position: relative;
  z-index: -1;
  width: 14px;
  margin-left: 4px;
  margin-bottom: 3px; }

@-webkit-keyframes expand {
  0% {
    transform: scaleY(0); }
  100% {
    transform: scaleY(1); } }

@keyframes expand {
  0% {
    transform: scaleY(0); }
  100% {
    transform: scaleY(1); } }

.indentation {
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  padding-left: 25px;
  transform-origin: top;
  -webkit-animation-name: expand;
          animation-name: expand;
  -webkit-animation-duration: 75ms;
          animation-duration: 75ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.outliner-buttons-container {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 0;
  float: right;
  margin-top: calc(4px / 2); }

.hover-top {
  border-top: calc(2px * 2) #00ADEF solid; }

.hover-middle {
  background-color: #00ADEF; }

.hover-bottom {
  border-bottom: calc(2px * 2) #00ADEF solid; }

.widget {
  vertical-align: top;
  max-width: 20px;
  max-height: 20px !important;
  width: 20px;
  height: 20px !important;
  border: none;
  background: none;
  padding: 0;
  margin-right: 4px;
  transition: background-color 150ms ease-in-out;
  border-radius: 3px; }

.widget-off {
  max-height: 20px;
  vertical-align: top;
  opacity: 0.25; }

.widget-on {
  max-height: 20px;
  vertical-align: top;
  opacity: 1; }

.outliner-title {
  height: 36px;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  display: flex;
  align-items: center; }

.outliner-title-container {
  box-shadow: 0px 2px 4px black;
  position: relative;
  z-index: 0; }

.outliner-title-name {
  font-size: 22px;
  color: white;
  font-family: 'Nunito Sans';
  display: inline-block; }

.outliner-title-selection-type {
  height: 80%;
  display: inline-flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin-left: 8px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.outliner {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.popout-outliner {
  border-right: 0; }

.outliner-body {
  color: white;
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.outliner-body:hover {
  overflow-y: auto; }

.outliner-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: flex;
  flex-direction: column; }

.outliner-script-icon {
  vertical-align: top;
  margin-top: calc(4px/2);
  height: 16px; }

.outliner-sound-icon {
  vertical-align: top;
  margin-top: 0;
  height: 20px;
  margin-right: 4px; }

.outliner-object {
  align-content: flex-start;
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  background-color: #3B3B3B;
  border-top: 2px solid #303030;
  border-bottom: 2px solid #303030; }

.outliner-object:hover {
  background-color: #4A4A4A;
  cursor: pointer; }

.outliner-object-selector {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 100%;
  border-style: none;
  background-color: transparent; }

.object-selected {
  border: 2px solid #1EE29A; }

.object-dragging {
  opacity: 0.5; }

.outliner-object-container {
  position: relative;
  width: 100%;
  height: 100%; }

.highlighted {
  background-color: #4A4A4A; }

.outliner-name {
  position: relative;
  z-index: -1;
  color: white;
  font-size: 16px;
  margin-left: calc(4px * 2); }

.row-icon {
  position: relative;
  z-index: -1;
  width: 14px;
  margin-left: 4px;
  margin-bottom: 3px; }

@-webkit-keyframes expand {
  0% {
    transform: scaleY(0); }
  100% {
    transform: scaleY(1); } }

@keyframes expand {
  0% {
    transform: scaleY(0); }
  100% {
    transform: scaleY(1); } }

.indentation {
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  padding-left: 25px;
  transform-origin: top;
  -webkit-animation-name: expand;
          animation-name: expand;
  -webkit-animation-duration: 75ms;
          animation-duration: 75ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.outliner-buttons-container {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 0;
  float: right;
  margin-top: calc(4px / 2); }

.hover-top {
  border-top: calc(2px * 2) #00ADEF solid; }

.hover-middle {
  background-color: #00ADEF; }

.hover-bottom {
  border-bottom: calc(2px * 2) #00ADEF solid; }

.widget {
  vertical-align: top;
  max-width: 20px;
  max-height: 20px !important;
  width: 20px;
  height: 20px !important;
  border: none;
  background: none;
  padding: 0;
  margin-right: 4px;
  transition: background-color 150ms ease-in-out;
  border-radius: 3px; }

.widget-off {
  max-height: 20px;
  vertical-align: top;
  opacity: 0.25; }

.widget-on {
  max-height: 20px;
  vertical-align: top;
  opacity: 1; }

.outliner-row {
  display: flex;
  align-items: center;
  width: 100%;
  height: 26px;
  margin-bottom: 0px; }

.outliner-row-identifier {
  display: flex;
  flex-direction: column;
  width: 30%;
  max-width: 30%;
  height: 100%;
  color: white;
  white-space: nowrap;
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  text-align: right;
  align-items: center;
  justify-content: center;
  padding-right: 1%;
  padding-left: 0;
  margin-top: 3px; }

.outliner-input-container {
  display: flex;
  align-items: center;
  width: 70%;
  height: 90%;
  margin-left: 4px; }

.outliner-input-element {
  display: inline-block;
  height: 100%;
  width: 100%; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.outliner-expand-button {
  position: absolute;
  top: 35px;
  right: 0px;
  height: 39px;
  width: 39px;
  color: white;
  background-color: #303030;
  border: 4px solid #191919;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-right-width: 0;
  transition: background-color 200ms ease-in-out; }

.outliner-expand-button:hover {
  background-color: #3B3B3B; }

.outliner-expand-button-closed {
  transform: scale(-1, 1); }

#wick-code-editor-resizeable {
    /* Local Variables */
    --handle-height: 40px;
    --reference-width: 160px;
    display: flex;
    box-shadow: var(--wick-box-shadow);
    border-radius: var(--border-radius-primary);
}

.we-code-editor-small {
    --handle-height: 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.wick-code-editor-body {
    display: flex;
    background-color: var(--wick-secondary);
    color: var(--wick-text-secondary);
    width: 100%;
    height: calc(100% - var(--handle-height));
    flex: 1 1;
    flex-direction: row;
}

.wick-code-editor-body-small {
    flex: 1 1;
    overflow-y: hidden;
}

.wick-code-editor-drag-handle {
    position: relative;
    width: 100%;
    height: var(--handle-height);
    background-color: var(--wick-primary);
    color: var(--wick-text-primary); 
    cursor: move;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: var(--reference-width);
    border-top-right-radius: var(--border-radius-primary);
    border-top-left-radius: var(--border-radius-primary);
}

.wick-code-editor-drag-handle.small { 
    padding-left: 0px;
}

.wick-code-editor-icon {
    position: absolute; 
    left: 40px;
    top: 10px;
    width: 60px;
    height: 60px;
    background-color: var(--wick-primary);
    border: none; 
    border-radius: 30px;
    font-family: 'Nunito';
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.wick-code-editor-tabs::-webkit-scrollbar {
    display: none;
  }


  /* Remove Scrollbar on Chrome, Opera */
.wick-code-editor-tabs::-webkit-scrollbar {
    display: none;
}

.wick-code-editor-tabs {
    height: var(--handle-height);
    width: 100%;
    background-color: var(--wick-secondary);
    display: flex;
    flex-direction: row;
    align-items: baseline;
    overflow-y: hidden;
    scrollbar-width: none;
}

.wick-code-editor-reference {
    width: var(--reference-width);
    min-width: var(--reference-width);
    background-color: var(--wick-secondary);
}

.wick-code-editor-content {
    display: flex;
    width: calc(100% - var(--reference-width));
    flex-direction: column;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.wick-code-editor-code::-webkit-scrollbar {
    display: none;
}
  
.wick-code-editor-code {
    background-color:black;
    height: 100%;
    max-width: 100%;
    overflow-x: scroll;
}

.ace-scroller {
    scrollbar-width: none;
}

.wick-code-editor-console {
    background-color: black;
    height: 100%; 
}

.we-code-close-button {
    width: 36px;
    height: 36px;

    margin-right: 4px;
}

.we-code-script-button {
    border: none;
    height: 90%;
    margin-top: auto;
    margin-right: 2px;
    border-top-left-radius: var(--border-radius-secondary);
    border-top-right-radius: var(--border-radius-secondary);
}

.add-script-container {
    display: flex;
    height: 100%;
    background-color: var(--wick-secondary);
    flex-direction: column;
  }

.add-script-buttons {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1;
    align-items: flex-start;
    background-color: var(--wick-secondary);
}

.add-script-button {
    border: none;
    background-color: var(--wick-primary);
    color: white;
    border-radius: var(--border-radius-secondary);
    margin: 6px;
    max-width: 150px;
    min-height: 150px;
    padding: 6px;

    transition: .3s all;
}

.add-script-button:hover {
    background-color: var(--wick-tertiary);
}

.add-script-button:disabled {
    background-color: var(--wick-disabled);
    color: var(--wick-text-disabled);
}

.add-script-button:disabled:hover {
    background-color: var(--wick-disabled);
    color: var(--wick-text-disabled);
}

.add-script-button-title {
    font-weight: bold;
    margin-bottom: 8px;
}

.add-script-tabs {
    margin: 6px;
    border-bottom: 4px solid var(--wick-primary);
}

.add-script-tab {
    margin-right: 2px;
    border: none;
    border-top-left-radius: var(--border-radius-secondary);
    border-top-right-radius: var(--border-radius-secondary);
}

.we-code-console-bar {
    width: 100%;
    height: 30px;
    background-color: var(--wick-primary);
    padding-left: 8px;
    display: flex;
    align-items: center;
    font-family: monospace;
}

.we-code-console-options-container {
    margin-left: auto;
    display: flex;
}

.we-code-console-option {
    width: 26px;
    height: 26px;
    margin-right: 6px;
}

.we-code-reference {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    height: calc(100% - 40px);
    scrollbar-width: 0px;
}

.reference-button {
    border-radius: var(--border-radius-secondary);
    border: none;
    width: 70%;
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
    transition: .3s all;
    display: flex;
    align-items: center;
    height: 28px;
}

.reference-button-title {
    display: flex;
    flex: 1 1;
    justify-content: center;
    margin-left: 4px;
}

.reference-icon {
    width: 20px;
    height: 20px;
}

.we-code-reference-title {
    color: white;
    font-size: 14px;
    font-weight: bold;
    justify-content: center;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
}

.we-code-reference-title-text {
    display: flex;
    justify-content: center;
}

.we-code-options {
    display: flex;
    flex-direction: column;
}

.we-code-options-selected {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.we-code-options-selected > .reference-button {
    margin: 0px;

}

.we-code-options-back {
    width: 28px;
    height: 28px;
    border-radius: var(--border-radius-secondary);
    background-color: var(--wick-primary);
    color: white;
    border: none;
    padding: 6px;
}

.we-code-options-body {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.code-option-button {
    display: inline-block;
    border: none;
    color: white;
    margin: 4px;
    margin-right: auto;
}

  /* Remove Scrollbar on Chrome, Opera */
  .we-code-reference-body::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
}

.we-code-reference-body {
    height: calc(100% - 40px);
    overflow-y: scroll;
    scrollbar-width: none;
}

.code-editor-option-input {
    width: 100%;
}

.action-button-reference {
    padding-left: 4px;
    padding-right: 4px;
    border-radius: var(--border-radius-secondary);
    min-width: 50px;
}

.we-code-editor-title {
    color: white;
    display: flex;
    flex-direction: row;
    margin-right: auto;
    white-space: nowrap;
}

.we-code-editor-title.small {
    font-size: 20px;
    font-weight: normal;
    padding-left: 4px;
}

.we-code-editor-title-selected {
    color: var(--wick-text-secondary);
    margin-left: 4px;
}

/* Theme Overrides */
.ace-monokai > .ace_gutter {
    background: #292929 !important;
    color: #FFFFFF ! important;
  }
  
.ace-monokai > .ace_scroller {
    background-color: #202020;
}

.ace-monokai > .ace_constant.ace_numeric {
    color: #CAADFF !important;
}

.ace-monokai > .ace_comment {
    color: #ABABAB !important;
}

.ace-monokai > .ace_keyword {
    color: #FF80B9 !important;
}

.error-marker {
    position: absolute;
    background-color: var(--wick-error);
    padding-right: 4px;
}

.we-code-add {
    background-color: var(--wick-tertiary); 
}

.ace_search {
    width: 250px;
}

.we-code-editor-title-error {
    color: var(--wick-error);
    padding-left: 4px;
}
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
.error-page {
  height: 100%;
  background-color: #1EE29A;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

#modal-close-icon-container {
  background-color: #383434;
  border: none;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
  -webkit-user-select: none;
  /* Safari */
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

#modal-close-icon-container:hover {
  cursor: pointer; }

#modal-icon-container {
  width: 60px;
  height: 60px; }

#modal-modal-icon {
  width: 100%;
  height: 100%; }

.modal-generic-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.object-info-title {
  color: #FFFFFF;
  font-size: 16px; }

.object-info-row {
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 3px;
  display: flex; }

.object-info-row-icon {
  float: left;
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  left: 0; }

.object-info-row-text {
  margin-left: 4px;
  color: #FFFFFF; }

.object-info-container {
  width: 100%;
  height: 140px;
  background-color: #525252;
  border-radius: 3px;
  margin-top: 10px;
  padding: 20px;
  padding-top: 10px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.make-interactive-modal-body {
  width: 450px;
  min-width: 240px;
  height: 320px;
  padding: 20px; }

#make-interactive-modal-title {
  font-size: 18px;
  color: #E6E6E6;
  font-weight: bold;
  text-align: left;
  width: 100%; }

#make-interactive-modal-interior-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center; }

#make-interactive-modal-footer {
  width: 100%;
  height: 28px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center; }

#make-interactive-modal-footer > div:last-child {
  margin-left: 12px; }

#make-interactive-asset-checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left; }

.make-interactive-asset-checkbox-input-container {
  width: 20px;
  height: 20px; }

#make-interactive-asset-checkbox-container {
  margin-top: 10px;
  width: 100%; }

#make-interactive-asset-checkbox-message {
  margin-left: 10px;
  color: #E6E6E6; }

#make-interactive-modal-name-input {
  margin-top: 10px;
  width: 100%; }

.make-interactive-object-info-container {
  display: flex;
  flex-direction: row; }

.make-interactive-object-info-container > div:last-child {
  margin-left: 12px; }

.make-interactive-modal-button {
  width: 50%; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.autosave-modal-body {
  width: 240px;
  min-width: 240px;
  height: 200px;
  padding: 20px; }

#autosave-modal-title {
  font-size: 18px;
  color: #E6E6E6;
  font-weight: bold;
  text-align: center;
  margin-top: 0px; }

#autosave-modal-interior-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 120px;
  justify-content: center;
  align-items: center; }

#autosave-modal-message {
  width: 100%;
  color: #E6E6E6;
  text-align: center;
  font-size: 14px; }

#autosave-modal-footer {
  width: 100%;
  height: 40px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

#autosave-modal-cancel {
  width: 90px;
  height: 100%; }

#autosave-modal-accept {
  width: 90px;
  height: 100%;
  margin-left: 18px; }

.autosave-modal-button-text {
  display: flex;
  align-items: center; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.welcome-modal-body {
  width: 670px;
  height: 420px;
  max-width: 670px;
  max-height: 420px;
  padding: 0px; }

#welcome-modal-interior-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%; }

#welcome-image-container {
  width: 50%;
  max-width: 320px; }

#welcome-image {
  height: 100%; }

#welcome-message-container {
  background-color: #1E1E1E;
  width: 350px;
  min-width: 350px;
  padding: 28px;
  padding-top: 30px;
  position: relative; }

#welcome-modal-title {
  color: #FFFFFF;
  font-size: 22px;
  font-weight: bold;
  white-space: nowrap; }

#welcome-modal-version {
  color: #E6E6E6;
  font-size: 16px;
  font-weight: 600; }

#welcome-modal-subtitle {
  color: #FFFFFF;
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px; }

#welcome-modal-message {
  color: #E6E6E6;
  font-size: 14px; }

#welcome-modal-forum-link {
  color: #E6E6E6;
  font-size: 14px;
  position: absolute;
  left: 28px;
  bottom: calc(28px + 40px); }

.welcome-modal-highlight {
  color: #1EE29A; }

.welcome-modal-main-container {
  height: 100%; }

#welcome-modal-footer {
  width: calc(100% - calc(2 * 28px));
  height: 28px;
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 28px; }

#welcome-modal-accept {
  width: 65px;
  height: 100%;
  margin-left: auto; }

#welcome-modal-tutorial-links {
  display: flex; }

.welcome-link {
  margin-left: 12px; }

.welcome-link:first-child {
  margin-left: 0px; }

.welcome-modal-mobile-body {
  width: 80%;
  height: 380px;
  max-width: 400px;
  padding: 0px;
  background-color: #1E1E1E !important; }

.welcome-modal-mobile-image {
  width: 100%; }

.welcome-modal-mobile-image-container {
  width: 100%;
  max-height: 30%;
  overflow: hidden; }

.welcome-modal-mobile-content {
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 0px; }

.welcome-modal-title {
  color: #FFFFFF;
  font-size: 22px;
  font-weight: bold;
  white-space: nowrap;
  font-family: "Nunito"; }

.welcome-modal-version {
  color: #E6E6E6;
  font-size: 16px;
  font-weight: 600; }

.supporter-list {
  color: white;
  font-size: 12px;
  height: 35%;
  max-height: 75px;
  overflow-y: scroll; }

.supporter-list-container.desktop-modal {
  margin-top: 20px; }

.updates-list {
  margin-bottom: 0px;
  color: white; }

.updates-list.small-modal li {
  font-size: 12px !important; }

#welcome-modal-mobile-accept {
  position: absolute;
  width: 65px;
  bottom: 20px;
  right: 20px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.make-animated-modal-body {
  width: 240px;
  min-width: 240px;
  height: 330px;
  padding: 20px; }

.make-animated-message-modal-button {
  font-family: Nunito; }

#make-animated-modal-title {
  font-size: 18px;
  color: #E6E6E6;
  font-weight: bold;
  text-align: left;
  width: 100%; }

#make-animated-modal-interior-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center; }

#make-animated-modal-footer {
  width: 100%;
  height: 28px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

#make-animated-modal-accept {
  width: 100%;
  height: 100%; }

#make-animated-asset-checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left; }

.make-animated-asset-checkbox-input-container {
  width: 20px;
  height: 20px; }

#make-animated-asset-checkbox-container {
  margin-top: 10px;
  width: 100%; }

#make-animated-asset-checkbox-message {
  margin-left: 10px;
  color: #E6E6E6; }

#make-animated-modal-name-input {
  margin-top: 10px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.tabbed-interface {
  width: 100%;
  height: 100%; }

.tabbed-interface-body {
  width: 100%;
  height: calc(100% - 30px); }

.tabbed-interface-main-tab-container {
  height: 30px;
  min-height: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: left;
  overflow: auto; }

.tabbed-interface-main-tab {
  background: none;
  border: none;
  margin-left: 15px;
  background-color: #383434;
  border: none;
  border-bottom: 2px solid #484747;
  color: #CFCFCF;
  font-size: 16px;
  cursor: pointer; }

.tabbed-interface-main-tab:first-child {
  margin-left: 0px; }

.tabbed-interface-main-tab.selected {
  transition: .4s;
  border-bottom: 2px solid #1EE29A;
  color: white; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.export-modal-body {
  width: 450px;
  min-width: 240px;
  padding: 20px;
  transition: height .5s, width .5s;
  transition-timing-function: ease-in-out; }

.export-modal-body.mobile {
  width: 90%;
  max-width: 400px; }

#export-modal-title {
  font-size: 18px;
  color: #E6E6E6;
  font-weight: bold;
  text-align: left;
  width: 100%; }

#export-modal-interior-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center; }

#export-modal-footer {
  width: 100%;
  height: 28px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center; }

#export-modal-footer > div:last-child {
  margin-left: 12px; }

#export-modal-name-input {
  margin-top: 10px;
  width: 100%; }

.export-info-container {
  display: flex;
  flex-direction: row;
  width: 100%; }

.export-info-container.mobile {
  flex-direction: column; }

.export-info-container:not(.mobile) > div:last-child {
  margin-left: 12px; }

.export-info-item {
  display: flex;
  flex-direction: column;
  width: 50%; }

.export-info-item.mobile {
  width: 100%;
  margin-bottom: 5px; }

.wide-export-info-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 0 !important; }

.export-modal-button-container {
  width: 100%;
  height: 28px;
  margin-top: 10px; }

.export-object-info {
  height: 120px; }

.export-object-info-off {
  background-color: #222222; }

#export-sub-tab-list {
  height: 40px;
  min-height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: left;
  overflow: auto; }

.export-modal-advanced-options {
  display: flex;
  flex-direction: column; }

.export-modal-advanced-option-title {
  color: white;
  font-size: 18px;
  margin-bottom: 0; }

.export-modal-advanced-option-label {
  display: inline-flex;
  color: white;
  font-size: 18px; }

.export-modal-advanced-checkbox-container {
  margin-top: 8px;
  display: flex;
  margin-left: auto; }

.advanced-resolution-table td {
  border-left: 0px;
  padding-left: 4px;
  color: white; }

#advanced-resolution-dropdown {
  width: 100px;
  margin-right: 8px; }

.export-modal-resolution-inputs {
  display: flex;
  flex-direction: row;
  align-items: center; }

.export-modal-resolution-label {
  text-align: left;
  color: #E6E6E6;
  margin-bottom: 2px; }

.export-modal-resolution-input-container {
  text-align: left;
  height: 25px;
  width: 50%; }

.export-modal-resolution-dropdown-container {
  vertical-align: middle;
  width: 50%;
  margin-top: auto; }

.export-modal-resolution-inputs-container {
  margin-left: 8px;
  display: flex;
  width: 50%;
  text-align: center; }

.export-modal-x-symbol {
  padding-top: 150%;
  padding-left: 5px;
  padding-right: 5px;
  color: #E6E6E6; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.general-warning-modal-body {
  width: 240px;
  min-width: 240px;
  height: 250px;
  padding: 20px; }

#general-warning-modal-title {
  font-size: 18px;
  color: #E6E6E6;
  font-weight: bold;
  text-align: center;
  margin-top: 20px; }

#general-warning-modal-interior-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center; }

#general-warning-modal-message {
  width: 100%;
  color: #E6E6E6;
  text-align: center;
  font-size: 14px; }

#general-warning-modal-footer {
  width: 100%;
  height: 40px;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

#general-warning-modal-cancel {
  width: 90px;
  height: 100%; }

#general-warning-modal-accept {
  width: 90px;
  height: 100%;
  margin-left: 18px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.media-export-modal-body {
  width: 300px;
  min-width: 240px;
  height: 160px;
  padding: 20px; }

.media-export-modal-content {
  padding-top: 15px;
  width: 100%;
  height: 100%; }

#media-export-modal-title {
  font-size: 18px;
  color: #E6E6E6;
  font-weight: bold;
  text-align: left;
  width: 100%; }

#media-export-modal-subtitle {
  font-size: 16px;
  color: #E6E6E6;
  padding-bottom: 4px; }

#media-export-modal-status-message {
  font-size: 14px;
  color: #E6E6E6; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

#project-settings-modal-title {
  font-size: 18px;
  color: #E6E6E6;
  font-weight: bold;
  text-align: center; }

.project-settings-property-label {
  color: #E6E6E6;
  margin-bottom: 0; }

.project-settings-split {
  padding-top: 150%;
  padding-left: 5px;
  padding-right: 5px;
  color: #E6E6E6; }

.project-setting-container {
  margin-top: 8px; }

.project-settings-size-input-container {
  display: flex;
  flex-direction: row; }

.project-settings-size-input-container.mobile {
  margin-bottom: 4px; }

.mobile-size {
  width: 140px;
  white-space: nowrap;
  overflow: hidden; }

#project-settings-modal-interior-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center; }

#project-settings-modal-footer {
  position: absolute;
  bottom: 18px;
  right: 18px;
  width: calc(100% - 40px);
  height: 28px;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: right; }

.project-settings-property-container {
  height: 30px;
  display: flex;
  flex-direction: row; }

.project-settings-modal-cancel {
  width: 80px;
  height: 100%;
  margin-left: auto; }

.project-settings-modal-cancel.mobile {
  width: calc(50% - 8px); }

.project-settings-modal-button-text {
  margin-bottom: auto; }

.project-settings-modal-accept {
  width: 80px;
  height: 100%;
  margin-left: 8px; }

.project-settings-modal-accept.mobile {
  width: calc(50% - 8px); }

.project-settings-modal-row {
  display: flex;
  flex-direction: row; }

.project-settings-modal-body {
  display: flex;
  flex-direction: column; }

.project-setting-element {
  width: 47.5%;
  margin-right: 5%;
  margin-top: 15px; }

.project-setting-element.mobile {
  width: 100%; }

.project-setting-element:last-child {
  margin-right: 0; }

.project-settings-modal-preset-text {
  color: white; }

.project-settings-modal-preset-text.hover {
  color: #303030; }

.project-settings-modal-preset-text.selected {
  color: #303030; }

.project-settings-modal-preset {
  height: 100%;
  width: 24%;
  margin-right: 1.25%;
  background-color: #525252;
  color: #E6E6E6;
  border-radius: 2px;
  line-height: 50px;
  vertical-align: center;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  /* Safari */
  transition-duration: .4s; }

.project-settings-modal-preset:hover {
  background-color: #29F1A3;
  color: #303030; }

.project-settings-modal-preset.selected {
  background-color: #1EE29A;
  color: #303030; }

.project-settings-modal-preset:last-child {
  margin-right: 0; }

.project-settings-presets-container {
  height: 50px;
  width: 100%; }

.project-settings-presets-body-container {
  display: flex;
  flex-direction: row; }

.preset-boxes {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row; }

#projectWidth {
  width: 50%; }

#projectHeight {
  width: 50%; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.editor-settings-modal-body {
  width: 100%;
  height: 100%; }

.editor-settings-group {
  margin-top: 8px;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 60px; }

.editor-settings-row {
  height: 40px;
  color: white;
  margin-top: 15px; }

.editor-settings-color-containers-row {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  height: 30px; }

.editor-settings-color-containers-row.mobile {
  flex-direction: column;
  width: 100%; }

.editor-settings-color-container {
  display: flex;
  width: 50%;
  height: 100%;
  flex: 0 1 auto;
  align-items: center;
  color: #E6E6E6;
  flex-direction: row;
  margin-left: 4px;
  margin-bottom: 4px; }

.editor-settings-color-container.mobile {
  width: 100%; }

.editor-settings-group-title {
  font-size: 20px;
  color: #E6E6E6; }

.editor-settings-group {
  color: white; }

.forward-backward-icon {
  height: 100%;
  margin-right: 4px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.keyboard-shortcuts-modal-button {
  height: 28px; }

.keyboard-shortcuts-modal-button-text {
  height: 24px; }

.keyboard-shortcuts-modal-body {
  width: 100%;
  height: 100%; }

.keyboard-shortcuts-modal-key-cell {
  justify-content: center; }

#keyboard-shortcuts-modal-title {
  font-size: 18px;
  color: #E6E6E6;
  font-weight: bold;
  text-align: center; }

.keyboard-shortcut-key {
  background: none;
  border: none;
  margin: 1px; }

#keyboard-shortcuts-modal-footer {
  position: absolute;
  bottom: 18px;
  right: 18px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-top: 4px; }

.keyboard-shortcuts-footer-button-container {
  width: 80px;
  margin-left: 8px; }

#keyboard-shortcuts-modal-reset {
  margin-left: 0; }

kbd {
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #b4b4b4;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
  color: #333;
  display: inline-block;
  font-size: .85em;
  font-weight: 700;
  line-height: 1;
  padding: 2px 4px;
  white-space: nowrap; }

.keyboard-shortcuts-key-icon-container {
  color: #E6E6E6; }

#keyboard-shortcuts-body {
  width: 100%;
  height: 85%;
  overflow: hidden; }

.hotkey-action-column {
  width: 45%;
  padding-left: 1.5em;
  color: #E6E6E6; }

.hotkey-header-column {
  text-align: left;
  width: 100%;
  border: none;
  font-size: 20px;
  padding-left: .5em;
  background-color: #292927;
  font-weight: bold;
  vertical-align: middle;
  cursor: pointer; }

.hotkey-header-column:hover {
  background-color: #353434;
  color: #1EE29A; }

.hotkey-column {
  width: calc(calc(100% - 45%) / 2); }

.hotkey-column:hover {
  cursor: pointer;
  background-color: #353434; }

.hotkey-column.header {
  color: white; }

.hotkey-column.header:hover {
  cursor: default;
  background-color: #383434; }

.hotkey-column.editing {
  background-color: #FFE243;
  color: black; }

.hotkey-column.edited {
  background-color: #E233F7;
  color: black; }

tr {
  width: 100%; }

thead {
  color: #B1B1B1; }

table {
  width: 100%; }

.tableSection {
  height: 100%;
  width: 100%; }

table.tableSection {
  display: table;
  width: 100%; }

table.tableSection thead, table.tableSection tbody {
  float: left;
  width: 100%; }

table.tableSection tbody {
  overflow: auto;
  height: 220px;
  border-bottom: 1px solid black;
  border-right: 1px solid black; }

table.tableSection tr {
  width: 100%;
  display: table;
  text-align: left;
  border-bottom: 1px solid black; }

td {
  border-left: 1px solid black; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.settings-modal-title {
  height: 32px;
  min-height: 32px;
  font-size: 18px;
  color: #E6E6E6;
  font-weight: bold;
  text-align: left;
  width: 100%; }

.settings-modal-body {
  width: 100%;
  height: 313px; }

.settings-modal-container {
  width: 500px;
  height: 375px; }

.settings-modal-container.mobile {
  width: 80%;
  max-width: 400px;
  height: 500px; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.builtin-library {
  width: 100%;
  height: 100%;
  padding: 20px; }

.builtin-library-modal-title {
  height: 30px;
  font-size: 18px;
  color: #E6E6E6;
  font-weight: bold;
  text-align: left;
  width: 100%; }

.builtin-library-asset-grid {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  margin-top: 5px; }

.builtin-library-asset {
  width: 30%;
  height: 170px;
  margin-right: 3%;
  margin-bottom: 10px; }

.builtin-library-asset-name {
  color: white;
  width: 100%;
  height: 30px;
  overflow: hidden; }

.builtin-library-asset-icon-container {
  width: 100%;
  height: 100px;
  background-color: #1E1E1E;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; }

.builtin-library-asset-icon {
  display: block;
  width: auto;
  height: 100%; }

.add-as-asset-button {
  width: 100%;
  height: 28px;
  margin: 3%;
  padding-right: calc(3% * 2); }

.preview-sound-button {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  height: 50px;
  margin-bottom: 12px; }

.preview-sound-button-text {
  position: relative;
  top: 25%; }

.audio-preview {
  width: 100%;
  height: 50px; }

.editor-info-modal-container {
  width: 250px;
  height: 325px; }

.editor-info-icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  margin-bottom: 8px; }

.editor-info-name {
  font-size: 24px;
  color: white;
  text-align: center; }

.editor-info-version {
  color: white;
  justify-content: center;
  text-align: center; }

.open-source-notices-modal-container {
  width: 600px;
  height: 400px; }

.open-source-notices-modal-container.mobile {
  width: 80%;
  max-width: 400px; }

.open-source-notices-body {
  margin-top: 25px;
  overflow: scroll;
  color: white;
  overflow-x: hidden;
  width: 100%; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.mobile-menu-close {
  position: absolute;
  right: 15px;
  top: 15px;
  min-width: 30px;
  min-height: 30px; }

.mobile-menu-close-icon {
  width: 30px; }

.no-bg {
  background: none; }

.mobile-menu-icon {
  width: 60px;
  height: 60px;
  fill: white; }

.mobile-menu-options-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 100%; }

.mobile-menu-mobile-body {
  height: 100%; }

.mobile-menu-option-text {
  font-family: "Nunito";
  color: white;
  font-size: 40px;
  text-align: left;
  position: relative;
  left: 10%;
  width: 70%;
  height: 60%; }

.mobile-menu-option {
  max-height: 20% !important; }

.mobile-menu-button {
  display: flex;
  justify-content: flex-start;
  padding-left: 16px; }

.saved-projects-modal-container {
  width: 100%;
  height: 100%;
  max-width: 250px;
  max-height: 350px; }

.saved-projects-modal-title {
  font-size: 24px;
  color: white; }

.saved-projects-modal-footer {
  display: flex;
  width: 75%;
  justify-content: space-between;
  margin-top: 8px; }

.saved-projects-modal-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  text-align: center;
  align-items: flex-start;
  overflow: scroll;
  margin-bottom: auto; }

.saved-projects-modal-button {
  width: 75px;
  height: 35px;
  font-size: 20px; }

.saved-projects-modal-button-text {
  display: flex;
  align-items: center;
  justify-content: center; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.saved-project-item {
  background-color: #303030;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 8px;
  width: 100%;
  display: inline-block;
  cursor: pointer;
  transition: .6s all;
  border: 4px solid #303030;
  box-sizing: border-box; }

.saved-project-item:hover {
  border-color: #E9AA02; }

.saved-project-item.selected {
  border-color: #01C094; }

.saved-project-item-name {
  font-size: 20px;
  text-align: left; }

.saved-project-item-detail-container {
  display: flex;
  justify-content: space-between;
  color: #BDBDBD; }

.simple-settings-modal-container {
  padding: 16px;
  padding-bottom: 32px;
  width: 240px; }

.simple-settings-modal-title {
  font-size: 24px;
  color: white;
  margin-top: 16px; }

.simple-settings-modal-body {
  display: flex;
  flex-direction: column; }

.simple-settings-form-row {
  width: 100%;
  margin-bottom: 8px; }

.simple-settings-input-label {
  color: white;
  display: flex;
  align-items: baseline;
  margin-bottom: 0; }

.simple-settings-modal-footer {
  display: flex;
  width: 100%;
  height: 35px;
  justify-content: center;
  margin-top: 16px; }

.simple-settings-action-button {
  width: 40%;
  margin: 8px; }

.simple-settings-input {
  height: 32px;
  border-radius: 4px;
  border: none;
  width: 100%; }

.simple-settings-dual-input {
  width: 45%; }

.simple-settings-dual-form-row {
  display: flex;
  justify-content: space-between; }

/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

a {
  color: #1EE29A; }

a:hover {
  color: #19C284; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #383434;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.mobile-menu-overlay {
  background-color: rgba(48, 48, 48, 0.95); }

.wick-brand-arrow {
  border: solid #1EE29A;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  display: inline-block;
  padding: 3px;
  margin: 4px; }

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

button:focus, input:focus {
  outline: 2px solid #FF7867; }

.popover.mobile .arrow {
  display: none;
  height: 0; }

.mobile.bs-popover-bottom {
  margin-top: 2px;
  margin-left: 2px; }

.support-modal-x-symbol {
  text-align: center;
  vertical-align: center;
  margin-left: 4px;
  margin-right: 4px;
  color: white; }

.support-modal-body {
  width: 568px;
  min-width: 240px;
  height: auto;
  padding: 20px;
  transition: height .5s, width .5s;
  transition-timing-function: ease-in-out;
  color: white;
  font-family: 'Nunito Sans'; }

#support-modal-interior-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; }

#support-modal-title {
  color: #E6E6E6;
  font-weight: bold;
  text-align: left;
  width: 100%;
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  align-items: center; }

#support-modal-title-img {
  width: 42px;
  height: auto;
  margin-right: 10px; }

#support-modal-title-text {
  font-size: 24px;
  margin-bottom: 0; }

#support-modal-benefit-text {
  display: inline-block;
  font-size: 18px; }

.support-modal-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%; }

.support-modal-col {
  background-color: #525252;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
  margin-bottom: 1em; }

.left-col {
  margin-right: 0.5em; }

.right-col {
  margin-left: 0.5em; }

.support-modal-col-title {
  margin: 0.5em 0 0 5%;
  display: flex;
  flex-direction: row;
  align-items: center; }

.support-modal-col-title-img {
  width: 20px;
  height: auto; }

.support-modal-col-title-text {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 0 5px; }

.support-modal-col-text {
  margin: 0.5em 0.5em 0.5em 5%; }

.support-modal-progress-bar {
  background-color: white;
  width: 90%;
  margin: 0 auto;
  border-radius: 10px; }

.support-modal-patreon-progress {
  background-color: #E85B46;
  border-radius: 10px 0 0 10px;
  height: 10px; }

.support-modal-github-progress {
  background-color: #EC6CB9;
  border-radius: 10px 0 0 10px;
  height: 10px; }

.support-modal-button {
  display: block;
  background-color: transparent;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  padding: 5px;
  margin: 0 auto 0.8em auto; }

.patreon-button {
  background-color: #E85B46;
  color: white;
  width: 80%; }

.github-button {
  background-color: white;
  color: black;
  width: 60%; }

.support-modal-button-img {
  width: 18px;
  height: auto;
  display: inline-block; }

.support-modal-button-text {
  display: inline-block;
  margin: 0 0 0 5px; }

#support-modal-follow-text {
  font-size: 18px;
  margin-bottom: 0; }

#support-modal-hashtag {
  color: #1EE29A;
  display: inline-block; }

#support-modal-social-icons {
  height: auto;
  margin: 0 auto; }

.support-modal-social-icon {
  display: inline-block;
  border: none;
  background-color: transparent; }

.support-modal-social-img {
  display: inline-block !important;
  width: 40px;
  height: auto; }

.support-modal-body-mobile {
  width: 300px;
  min-width: 240px;
  height: auto;
  padding: 20px;
  transition: height .5s, width .5s;
  transition-timing-function: ease-in-out;
  color: white;
  font-family: 'Nunito Sans'; }

#support-modal-title-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #E6E6E6;
  font-weight: bold;
  text-align: left;
  width: 100%;
  margin-bottom: 0.5em; }

.support-modal-title-img-mobile {
  width: 24px;
  height: auto;
  margin-right: 10px;
  display: inline-block; }

