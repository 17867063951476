/*
 * Copyright 2020 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'Editor/_editor.scss';

.timeline {
  background-color: $flame-dark;
}

#animation-timeline-container {
  height: floor(100%);
}

#animation-timeline {
  width: floor(100%);
  height: 100%;
  border-left: $editor-outline-padding solid $editor-primary-outline;
  border-bottom: $editor-outline-padding solid $editor-primary-outline;
}

.animation-timeline-add-keyframe-button {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 27px;
  left: 170px;
}
